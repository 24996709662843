export const region = [
  { val: "AT", option: "AT" },
  { val: "AU", option: "AU" },
  { val: "BE", option: "BE" },
  { val: "BR", option: "BR" },
  { val: "CA", option: "CA" },
  { val: "CH", option: "CH" },
  { val: "DE", option: "DE" },
  { val: "DK", option: "DK" },
  { val: "ES", option: "ES" },
  { val: "FI", option: "FI" },
  { val: "FR", option: "FR" },
  { val: "GB", option: "GB" },
  { val: "IE", option: "IE" },
  { val: "IT", option: "IT" },
  { val: "NL", option: "NL" },
  { val: "NO", option: "PL" },
  { val: "PL", option: "PL" },
  { val: "SE", option: "SE" },
  { val: "US", option: "US" },
];
