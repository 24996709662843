import { Button, ButtonGroup, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./rakuten-linkshare.styles.scss";
import { timeZone } from "../../const/timeZone3";
import { money } from "../../const/currency";
import { useEffect, useState } from "react";
import { Toastify } from "../../../../App";
const RakutenLinkshare = ({ Connect, currentNetImg }) => {
  const time = timeZone;
  const currency = money;
  const [details, setDetails] = useState({
    username: "",
    password: "",
    time_zone: "",
    mid: "",
    network_commision_rate: {
      ncr_val: 0,
      ncr_type: "commision",
    },
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    console.log(e, "chcki");
    if (e.target.name === "ncr_val" || e.target.name === "ncr_type") {
      console.log("click");
      setDetails({
        ...details,
        network_commision_rate: {
          ...details.network_commision_rate,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );
    const data = networks_collection?.["rakuten-linkshare"];

    if (data !== undefined && data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
        network_commision_rate: {
          ...data?.network_commision_rate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkPass = () => {
    if (details.username === "" || details.username.length === "") {
      return "Accout Username  required !!";
    } else if (details.password.length === 0 || details.password === "") {
      return "Auth Password required !!";
    } else if (details.timezone.length === 0 || details.timezone === "") {
      return "Timezone required !!";
    } else if (details.mid.length === 0 || details.mid === "") {
      return "MID  required !!";
    } else if (details.currency === "" || details.currency.length === 0) {
      return "Currency required !!";
    }
    return "";
  };

  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="rakuten-linkshare-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="Username"
          style={{
            margin: "15px 0",
          }}
          name="username"
          value={details.username}
          // value={addOrgName}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          required
          size="small"
          label="Password"
          type="password"
          style={{
            margin: "15px 0",
          }}
          name="password"
          value={details.password}
          // value={addOrgName}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          select
          required
          size="small"
          label="Select a timezone (usually GMT)"
          style={{
            margin: "15px 0",
          }}
          value={details.time_zone}
        >
          <MenuItem>Please select a timezone..</MenuItem>
          {time.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "time_zone",
                      value: t.time,
                    },
                  });
                }}
                value={t.time}
              >
                {t.time}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          required
          size="small"
          label="MID"
          style={{
            margin: "15px 0",
          }}
          value={details.mid}
          name="mid"
          onChange={(event) => onDetailChange(event)}
        />
        <div className="number-and-option">
          <TextField
            error={false}
            size="small"
            label="Network Commission Rate (Optional)"
            type="number"
            style={{
              width: "100%",
              margin: "15px 0",
            }}
            name="ncr_val"
            value={details.network_commision_rate.ncr_val}
            onChange={(event) => onDetailChange(event)}
          />
          <div
            style={{ fontSize: "1rem", fontWeight: "500", padding: "0 10px" }}
          >
            {" "}
            Of{" "}
          </div>
          <ButtonGroup
            style={{
              width: "100%",
            }}
          >
            <Button
              variant={
                details.network_commision_rate.ncr_type === "commision"
                  ? "contained"
                  : "outlined"
              }
              onClick={(event) =>
                onDetailChange({
                  target: {
                    name: "ncr_type",
                    value: "commision",
                  },
                })
              }
            >
              Commision
            </Button>
            <Button
              variant={
                details.network_commision_rate.ncr_type === "revenue"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                onDetailChange({
                  target: {
                    name: "ncr_type",
                    value: "revenue",
                  },
                })
              }
            >
              Revenue
            </Button>
          </ButtonGroup>
        </div>
        <TextField
          error={false}
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "15px 0",
          }}
          value={details.currency}
        >
          {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
          {/* {console.log(orgs, "orgs")} */}
          <MenuItem>Please select a timezone..</MenuItem>

          {currency.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: t.option,
                    },
                  });
                }}
                value={t.option}
              >
                {t.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          console.log(details, "details");

          Connect(details, "rakuten-linkshare");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default RakutenLinkshare;
