import React, { useState } from "react";
import { Button } from "@mui/material";
import Spinner from "react-spinner-material";
import PasswordValidator from "password-validator";
import axios from "axios";
import "./forgot-password-change.scss";
import AuthFormInput from "../auth-form-input";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "../modal";
import { Toastify } from "../../App";
import { useNavigate } from "react-router-dom";

const ForgotPasswordChange = ({ changeUserAction, email }) => {
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigation = useNavigate();
  const continueClicked = () => {
    setShowModal(false);

    changeUserAction("login");
  };

  const handleSubmit = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();

    if (otp == null || otp === "") {
      setOtpError("Required!");
      setPasswordError("");
      setConfirmPasswordError("");
    } else if (password == null || password === "") {
      setOtpError("");
      setPasswordError("Required!");
      setConfirmPasswordError("");
      return;
    } else if (confirmPassword == null || confirmPassword === "") {
      setOtpError("");
      setPasswordError("");
      setConfirmPasswordError("Required!");
      return;
    } else if (!passwordSchema.validate(password)) {
      setOtpError("");
      setPasswordError(
        "Password should be of length 6 - 10 and should be containing atleast 1 uppercase, 1 lowercase and 1 digit and should not have any spaces"
      );
      setConfirmPasswordError("");
      return;
    } else if (password !== confirmPassword) {
      setOtpError("");
      setPasswordError("");
      setConfirmPasswordError("Doesn't match password");
      return;
    } else {
      setOtpError("");
      setPasswordError("");
      setConfirmPasswordError("");
      setShowLoading(true);

      axios
        .post(
          "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/password/update",
          {
            email: email,
            otp: otp,
            password: password,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Toastify("success", "Password updated");
            navigation("/auth", { state: "login" });
          }
        });
    }
  };

  return (
    <div className="forgot-pwd-change-component">
      <p className="title-forgot-pwd-change">Change Password</p>
      <p className="title-forgot-pwd-change-tag">
        Get access to exclusive analytics by LT Partners
      </p>
      <div className="email-change-div">
        <p className="text">
          Email :<span className="email">{email}</span>
        </p>
        <p className="btn" onClick={() => changeUserAction("forgot-pwd")}>
          Change
        </p>
      </div>
      <AuthFormInput
        label="OTP"
        type="text"
        hint="ABC123"
        value={otp}
        changeFunction={(event) => setOtp(event.target.value)}
        error={otpError}
      />
      <AuthFormInput
        label="Password"
        type="password"
        hint="paS5w0rD"
        value={password}
        changeFunction={(event) => setPassword(event.target.value)}
        error={passwordError}
      />
      <AuthFormInput
        label="Confirm Password"
        type="password"
        hint="Same as Password"
        value={confirmPassword}
        changeFunction={(event) => setConfirmPassword(event.target.value)}
        error={confirmPasswordError}
      />
      <Button className="continue-btn" onClick={handleSubmit}>
        {showLoading ? (
          <div className="loading">
            <Spinner
              className="spin"
              radius={23}
              color={"white"}
              stroke={3}
              visible={true}
            />
            <div className="spacer">Applying Changes</div>
          </div>
        ) : (
          "Change Password"
        )}
      </Button>

      <Modal show={showModal}>
        <ModalHeader>
          <p>Password reset successful!</p>
        </ModalHeader>
        <ModalBody>
          <p>
            Your password has been reset successfully! Please login again to
            continue.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-accept" onClick={continueClicked}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ForgotPasswordChange;
