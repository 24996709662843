import classes from "./navItem.module.scss";
import { HiOutlineChevronRight as Icon } from "react-icons/hi";

import { useDispatch, useSelector } from "react-redux";

const NavItem1 = (props) => {
  return (
    <>
      <div className={`${classes.container}`}>
        <button>
          <span>{props.text}</span>
        </button>
      </div>
    </>
  );
};

export default NavItem1;
