export const timeZone = [
  {
    value: 1,
    time: "America/Sao_Paulo",
  },
  {
    value: 2,
    time: "Australia/Sydney",
  },
  {
    value: 3,
    time: "Europe/Berlin",
  },
  {
    value: 4,
    time: "Europe/Dublin",
  },
  {
    value: 5,
    time: "Europe/Helsinki",
  },
  {
    value: 6,
    time: "Europe/London",
  },
  {
    value: 7,
    time: "Europe/Paris",
  },
  {
    value: 8,
    time: "Canada/Central",
  },
  {
    value: 9,
    time: "Canada/Eastern",
  },
  {
    value: 10,
    time: "Canada/Mountain",
  },
  {
    value: 11,
    time: "Canada/Pacific",
  },
  {
    value: 12,
    time: "US/Central",
  },
  {
    value: 13,
    time: "US/Eastern",
  },
  {
    value: 14,
    time: "US/Mountain",
  },
  {
    value: 15,
    time: "US/Pacific",
  },
  {
    value: 16,
    time: "UTC",
  },
];
