import React, { Fragment } from "react";
import classes from "./navbar.module.scss";
import { Link } from "react-router-dom";
import NavItem1 from "../NavItem1";
// import logo from "../../../../assests/download.png"
import logo from "../../../../assests/logo/lift_logo_pink-cloud.png";
import { useDispatch, useSelector } from "react-redux";
import {
  menu_action,
  navbar_actions,
  navbar_actions1,
} from "../../../../redux/actions";

const options = [
  {
    id: "whatislift",
    text: `What is LIFT?`,
    type: "one",
    // data: [{
    //     link: "/",
    //     head: "Overview1",
    //     desc: "Welcome to Airtable! Here's the basics"
    // }, {
    //     link: "/",
    //     head: "Features",
    //     desc: "Discover automation, views, reportings, and more"
    // }, {
    //     link: "/",
    //     head: "Overview2",
    //     desc: "Welcome to Airtable! Here's the basics",
    //     data: [{
    //         icon: <Photo size={25} />,
    //         head: "Views",
    //         desc: "Explore different ways to view data"
    //     },
    //     {
    //         icon: <Thunder size={25} />,
    //         head: "Interface Designer",
    //         desc: "Turn your base into a working product"
    //     },
    //     {
    //         icon: <People size={25} />,
    //         head: "Automations",
    //         desc: "Simplify and streamline workflows"
    //     },
    //     {
    //         icon: <Magic size={25} />,
    //         head: "Magic",
    //         desc: "Simplify and streamline workflows"
    //     }]
    // }, {
    //     link: "/",
    //     head: "Overview3",
    //     desc: "Welcome to Airtable! Here's the basics"
    // }, {
    //     link: "/",
    //     head: "Features4",
    //     desc: "Discover automation, views, reportings, and more"
    // }, {
    //     link: "/",
    //     head: "Overview5",
    //     desc: "Welcome to Airtable! Here's the basics"
    // }],

    footer_head: "Pricing",
    footer_desc:
      "From small business to global enterprise, there’s an Airtable plan that’s just right for you.",
  },
  {
    id: "Dashboards",
    text: "Dashboards",
    type: "more",
    data: [
      {
        link: "/",
        head: "Home",
        desc: "Get a clear picture of your brand’s affiliate program with monthly vanity metrics and top publisher analytics.",
      },
      {
        link: "/",
        head: "Top Performers",
        desc: "Look at top publishers and channels based on revenue, traffic, conversions, and more.",
      },
      {
        link: "/",
        head: "Performance Comparison",
        desc: "Understand your brand’s historical performance against customizable periods.",
      },
      {
        link: "/",
        head: "Publisher Scorecard",
        desc: "Score publishers in your program based on revenue, AOV, clicks, CR, new users, and more.",
      },
      {
        link: "/",
        head: "Paid Placement",
        desc: "Understand your placement’s performance with our state-of-the-art report analysis.",
      },
      {
        link: "/",
        head: "Network vs. Google Analytics Comp",
        desc: "Figure out the difference between your network and Google Analytics report revenue with a single click.",
      },
      {
        link: "/",
        head: "Monthly KPIs",
        desc: "Overlook monthly brand performance based on new actions, spend, ROAS, and more.",
      },
      {
        link: "/",
        head: "Placement Comparison",
        desc: "Find out the best placements to run by comparing their performances at a single place.",
      },
    ],

    footer_head: "Pricing",
    footer_desc:
      "From small business to global enterprise, there’s an Airtable plan that’s just right for you.",
  },
  {
    id: "Integrations",
    text: "Integrations",
    type: "more",
    data: [
      {
        link: "/",
        head: "Affluent",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "AWIN",
        // desc: "Discover automation, views, reportings, and more",
      },
      {
        link: "/",
        head: "Commission Junction",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "Google Analytics",
        // desc: "Discover automation, views, reportings, and more",
      },
      {
        link: "/",
        head: "Impact",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "Pepperjam",
        // desc: "Discover automation, views, reportings, and more",
      },
      {
        link: "/",
        head: "Rakuten LinkShare",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "ShareASale",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "Everflow",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "Partnerize",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "DaisyCon",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "LinkConnector",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Odyssey",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "oneNetworkDirect",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Paid on Results",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Tune",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Trafedoubler",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "TradeTracker",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Refersion",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Webgains",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "AdTraction",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Admitad",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "PartnerStack",
        // desc: "Welcome to Airtable! Here's the basics",
      },

      {
        link: "/",
        head: "Commission Factory",
        // desc: "Welcome to Airtable! Here's the basics",
      },
      {
        link: "/",
        head: "AvantLink",
        // desc: "Welcome to Airtable! Here's the basics",
      },
    ],

    footer_head: "Pricing",
    footer_desc:
      "From small business to global enterprise, there’s an Airtable plan that’s just right for you.",
  },
  {
    id: "prices",
    text: "Pricing",
    type: "one",
  },
  {
    id: "blogs",
    text: "Blogs",
    type: "one",
  },
  //   {
  //     id: "resources",
  //     text: "Resources",
  //     type: "more",
  //     data: [
  //       {
  //         link: "/",
  //         head: "Overview14",
  //         desc: "Welcome to Airtable! Here's the basics",
  //       },
  //       {
  //         link: "/",
  //         head: "Features15",
  //         desc: "Discover automation, views, reportings, and more",
  //       },
  //       {
  //         link: "/",
  //         head: "Overview16",
  //         desc: "Resource descriptions ",
  //       },
  //     ],

  //     footer_head: "Pricing",
  //     footer_desc:
  //       "From small business to global enterprise, there’s an Airtable plan that’s just right for you.",
  //   },
];
const NavBar = ({ tnc }) => {
  // const [navbar, setNavBar] = React.useState("");
  const nav_tab = useSelector((state) => state.nav_bar);
  const [user, setUser] = React.useState(null);

  // const getInvitation = async (data) => {
  //   try {
  //     if (data?.token == undefined) {
  //       console.log(data, "testing");
  //       return;
  //     }
  //     const res = await axios.get(
  //       `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/invite/details?token=${data?.token}`
  //     );

  //     if (
  //       res.data?.details === undefined ||
  //       res.data?.details === null ||
  //       res.data?.details === {}
  //     ) {
  //       //
  //     } else {
  //       setUser(data);
  //     }
  //   } catch (error) {}
  // };
  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    if (data?.token) {
      setUser(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  // console.log(nav_tab, "nav_tab")
  return (
    <nav className={classes.container}>
      <div className={classes.part1}>
        <Link
          to={"/"}
          className={`${classes.logo} ${
            nav_tab.nav_tab !== "none" ? classes.logo_active : ""
          }`}
        >
          <span>
            <img alt="logo" src={logo} />
          </span>
          {/* <span>Partners</span> */}
        </Link>
        <div
          className={` ${nav_tab.menu ? classes.option_open : ""} ${
            classes.options
          } ${nav_tab.nav_tab !== "none" ? classes.options_active : ""}`}
        >
          {options.map((data, idx) => {
            if (data.id === "Integrations") {
              return (
                <Fragment key={idx}>
                  <NavItem1
                    tnc={tnc}
                    footer_desc={data.footer_desc}
                    footer_head={data.footer_head}
                    data={data.data}
                    action={nav_tab.nav_tab}
                    clickAction={navbar_actions}
                    id={data.id}
                    text={data.text}
                    type={data.type}
                  />
                </Fragment>
              );
            }
            return (
              <Fragment key={idx}>
                <NavItem1
                  tnc={tnc}
                  footer_desc={data.footer_desc}
                  footer_head={data.footer_head}
                  data={data.data}
                  action={nav_tab.nav_tab}
                  clickAction={navbar_actions}
                  id={data.id}
                  text={data.text}
                  type={data.type}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className={classes.part2}>
        <a
          rel="noreferrer"
          href="https://calendly.com/lt-partners-lacie-steve-john/lift-demo"
          target={"_blank"}
          className={classes.link1}
        >
          Book A Demo
        </a>

        {user != null ? (
          <a
            rel="noreferrer"
            href={
              user?.team === 1
                ? `https://app.lift.lt.partners/testload?_id=${user.id}&token=${user.token}`
                : `https://app.lift.lt.partners/testload?_id=${user.id}&token=${user.token}&onboard=1`
            }
          >
            Go to LIFT
          </a>
        ) : (
          <>
            <Link
              state={{ action: "login", type: 0 }}
              to={"/auth"}
              className={classes.link2}
            >
              Login
            </Link>
            {/* <Link
              state={{ action: "reg" }}
              to={"/auth"}
              className={classes.link2}
            >
              Sign Up
            </Link> */}
          </>
        )}

        <button
          className={`${nav_tab.menu ? classes.burger_menu_action : ""} ${
            classes.burger_menu
          }`}
          onClick={() => {
            dispatch(navbar_actions("none"));
            dispatch(navbar_actions1("none"));
            dispatch(menu_action(!nav_tab.menu));
          }}
        >
          <div className={`${classes.line} ${classes.l1}`}></div>
          <div className={`${classes.line} ${classes.l2}`}></div>
          <div className={`${classes.line} ${classes.l3}`}></div>
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
