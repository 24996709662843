import { Button, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./refersion.styles.scss";
import { useEffect, useState } from "react";
import { Toastify } from "../../../../App";
const Refersion = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    refersion_graphql_token: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );

    const data = networks_collection?.["refersion"];

    if (data !== undefined &&  data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
        network_commision_rate: {
          ...data?.network_commision_rate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkPass = () => {
    if (
      details.refersion_graphql_token.length === 0 ||
      details.refersion_graphql_token === ""
    ) {
      return "Refersion GraphQL Token required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="refersion-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="Refersion GraphQL Token"
          style={{
            margin: "15px 0",
          }}
          name="refersion_graphql_token"
          value={details.refersion_graphql_token}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect(details, "refersion");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Refersion;
