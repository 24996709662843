import React, { useEffect, useRef } from "react";
import classes from "./priceing.module.scss";
// import classes from "./priceing.module.scss";
import { Link } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { price_ref_action } from "../../../../redux/actions";

const Price = () => {
  const priceRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(price_ref_action(priceRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRef]);

  const monthly = ["Billed Monthly"];
  const yearly = ["Save 20% by paying annually", "Billed yearly"];

  return (
    <div className={classes.container} ref={priceRef}>
      <div className={classes.wrapper}>
        <div className={classes.heading}>
          <h1>Choose the right plan for you</h1>
        </div>
        <div className={classes.price_container}>
          <div className={classes.bg_price}>
            <div className={classes.content}>
              <h3>Subscription</h3>
              <div className={classes.horizontalBreak}></div>
              <p>
                Choose the subscription model that works the best according to
                your needs!
              </p>
            </div>
          </div>
          <div className={classes.pricings}>
            <div className={`${classes.card_container} ${classes.bg_color1}`}>
              <div className={`${classes.items} ${classes.plan}`}>$1,000</div>
              <div className={`${classes.items} ${classes.head}`}>Monthly</div>
              <div className={`${classes.items} ${classes.billing}`}>
                {/* <strong>$1,000</strong> billing monthly */}
              </div>
              <div className={`${classes.items} ${classes.btn_cnt}`}>
                <Link to="/admin/onboard" target={"_blank"}>
                  Monthly
                </Link>
              </div>
              <div className={`${classes.items} ${classes.list_container}`}>
                <div className={classes.head_list}></div>
                <div className={`${classes.list} `}>
                  {monthly.map((d, i) => {
                    return (
                      <div className={classes.item} key={i}>
                        <div className={classes.icon}>
                          <BsCheckLg />
                        </div>
                        <span>{d}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={`${classes.card_container} ${classes.bg_color2}`}>
              <div className={`${classes.items} ${classes.top_bar}`}>
                Most Popular
              </div>
              <div className={`${classes.items} ${classes.plan}`}>$10,000</div>
              <div className={`${classes.items} ${classes.head}`}>Yearly</div>
              <div className={`${classes.items} ${classes.billing}`}>
                {/* <strong>$10,000</strong> billing annually */}
              </div>
              <div className={`${classes.items} ${classes.btn_cnt}`}>
                <Link to="/admin/onboard" target={"_blank"}>
                  Yearly
                </Link>
              </div>
              <div className={`${classes.items} ${classes.list_container}`}>
                <div className={classes.head_list}></div>
                <div className={classes.list}>
                  {yearly.map((d, i) => {
                    return (
                      <div className={classes.item} key={i}>
                        <div className={classes.icon}>
                          <BsCheckLg />
                        </div>
                        <span>{d}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
