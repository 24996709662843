import React, { useState } from "react";
import { Button } from "@mui/material";
import Spinner from "react-spinner-material";
import validator from "validator";
import axios from "axios";

import "./forgot-password.scss";

import AuthFormInput from "../auth-form-input";
import { Toastify } from "../../App";

const ForgotPassword = ({ changeUserAction, changeEmail }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const handleSubmit = () => {
    if (email == null || email === "") {
      setEmailError("Required!");
      return;
    } else if (!validator.isEmail(email)) {
      setEmailError("Invalid Email!");
      return;
    } else {
      setEmailError("");

      setShowLoading(true);

      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/password/forgot?email=${email}`,
          {
            email: email,
          }
        )
        .then((_) => {
          Toastify("success", "Please check your email for OTP!!");
          setShowLoading(false);
          changeUserAction("forgot-pwd-change");
          changeEmail(email);
        })
        .catch((err) => {
          setShowLoading(false);
          setEmailError(err.response.data.message);
        });
    }
  };

  return (
    <div className="forgot-pwd-component">
      <p className="title-forgot-pwd">Reset Password</p>
      <p className="title-forgot-pwd-tag">
        Get access to exclusive analytics by LT Partners
      </p>
      <AuthFormInput
        label="Email"
        type="email"
        hint="jane@lt.partners"
        value={email}
        changeFunction={(event) => setEmail(event.target.value)}
        error={emailError}
      />
      <Button className="otp-btn" onClick={handleSubmit}>
        {showLoading ? (
          <div className="loading">
            <Spinner
              className="spin"
              radius={23}
              color={"white"}
              stroke={3}
              visible={true}
            />
            <div className="spacer">Sending OTP</div>
          </div>
        ) : (
          "Get OTP"
        )}
      </Button>
    </div>
  );
};

export default ForgotPassword;
