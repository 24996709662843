import { Link } from "react-router-dom";
import classes from "./integration.module.scss";
import { AiOutlineArrowRight } from "react-icons/ai";
import Apps from "./Apps";
import img2 from "../../../../assests/compo/Integration/AWIN.png";
import img3 from "../../../../assests/compo/Integration/Commission Junction.png";
import img4 from "../../../../assests/compo/Integration/GA Logo.png";
import img5 from "../../../../assests/compo/Integration/Impact.png";
import img6 from "../../../../assests/compo/Integration/Pepperjam.png";
import img7 from "../../../../assests/compo/Integration/Rakuten LinkShare.png";
import img8 from "../../../../assests/compo/Integration/Shareasale.png";
import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  integration_ref_action,
  navbar_actions,
  navbar_actions1,
} from "../../../../redux/actions";

const data = [
  // {
  //   img: img1,
  //   name: "Affluent",
  // },
  {
    img: img2,
    name: "AWIN",
  },
  {
    img: img3,
    name: "Commission Junction",
  },
  {
    img: img4,
    name: "Google Analytics",
  },
  {
    img: img5,
    name: "Impact",
  },
  {
    img: img6,
    name: "Pepperjam",
  },
  {
    img: img7,
    name: "Rakuten LinkShare",
  },

  {
    img: img8,
    name: "ShareASale",
  },
];

const Integration = () => {
  const integrationRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(integration_ref_action(integrationRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationRef]);
  return (
    <section className={classes.container} ref={integrationRef}>
      <div className={classes.wrapper}>
        <div className={classes.integration_content}>
          <div className={classes.left}>
            <div>
              <p>Integrations</p>
              <h2>Keep using your favorite platforms</h2>
            </div>
          </div>
          <div className={classes.right}>
            <div>
              <p className={classes.p_content}>
                Whatever platform you use, we have got you covered with more
                than 40+ integrations out of the box…
              </p>
              <Link
                onClick={() => {
                  dispatch(navbar_actions("Integrations"));
                  dispatch(navbar_actions1("none"));
                }}
              >
                <p>See all integrations</p>
                <AiOutlineArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.integration_apps}>
          <div className={classes.apps_}>
            <div className={classes.apps_box}>
              {data.map((d, i) => {
                return (
                  <Fragment key={i}>
                    <Apps idx={i * Math.random(10)} img={d.img} name={d.name} />
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integration;
