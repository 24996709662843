import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./affiliate-future.styles.scss";
import { useEffect, useState } from "react";
import { money } from "../../const/currency";
import { Toastify } from "../../../../App";
const AffiliateFuture = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    username: "",
    password: "",
    account_type: "",
    currency: "",
    nick_name: "",
  });

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );

    const data = networks_collection?.["affiliate-future"];

    if (data !== undefined && data !== null) {
      setDetails({
        ...data,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
    console.log(details);
  };
  const currency = money;
  const checkPass = () => {
    console.log(details, "detail");
    if (details.username.length === 0 || details.username === "") {
      return "Username required !!";
    } else if (details.password.length === 0 || details.password === "") {
      return "Password required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    } else if (
      details.account_type.length === 0 ||
      details.account_type === ""
    ) {
      return "Account Type required !!";
    }
    return "";
  };
  const accounts = ["Advertiser", "Agency"];
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="affiliate-future-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="Username"
          style={{
            margin: "15px 0",
          }}
          name="username"
          value={details.username}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          required
          size="small"
          label="Password"
          style={{
            margin: "15px 0",
          }}
          type="password"
          name="password"
          value={details.password}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          select
          required
          size="small"
          label="What type of account is this?"
          style={{
            margin: "15px 0",
          }}
          value={details.account_type}
        >
          <MenuItem>Choose type of account</MenuItem>
          {accounts.map((a, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "account_type",
                      value: a,
                    },
                  });
                }}
                value={a}
              >
                {a}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "15px 0",
          }}
          value={details.currency}
        >
          <MenuItem>Choose type of currency</MenuItem>
          {currency.map((a, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: a.option,
                    },
                  });
                }}
                value={a.option}
              >
                {a.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect(details, "affiliate-future");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default AffiliateFuture;
