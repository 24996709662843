import "./form-container.style.scss";
import AddIcon from "@mui/icons-material/Add";
const FormContainer = ({ children, currentNetImg }) => {
  return (
    <div className="form-container">
      <div className="header">
        <div className="logo">
          <img
            alt=""
            style={{
              width: "125px",
              borderRadius: "5px",
              backgroundColor: "#fff",
              padding: "15px",
            }}
            src={currentNetImg}
          />
          <AddIcon
            style={{
              color: "#b51783",
              fontSize: "2rem",
            }}
          />
          <img alt="" src={`https://admin.lift.lt.partners/assets/lift.png`} />
        </div>
        <div className="title">{}</div>
      </div>
      <div className="form-box">{children}</div>
    </div>
  );
};

export default FormContainer;
