import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ActiveBackground from "../../components/ActiveBackground";
import NavBar from "../../components/partials/NavBarComponents/NavBar";
import "./view-blogs.style.scss";
import moment from "moment";
import Footer from "../../components/partials/Footer";
import validator from "validator";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Toastify } from "../../App";

const ViewBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openContactUsProgress, setOpenContactUsProgress] = useState(false);

  const getAllPublish = async () => {
    try {
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/pages/get`
      );
      console.log(res, "result");
      if (res.status === 200) {
        let data = res.data.filter((d) => {
          if (d.public === 1 && d.published === 1) {
            return d;
          }
        });
        console.log(data, "data");
        setBlogs(data.sort(customSortBasedOnDate).reverse());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPublish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDate = (date) => {
    return moment(date).format("L");
  };

  const [message, setmessage] = useState({
    name: "",
    email: "",
    query: "",
  });
  const sendQuery = async () => {
    try {
      if (
        typeof message.name === "undefined" ||
        message.name === null ||
        message.name === ""
      ) {
        Toastify("warning", "Name is required!");
      } else if (
        typeof message.email === "undefined" ||
        message.email === null ||
        message.email === ""
      ) {
        Toastify("warning", "Email is required!");
      } else if (
        typeof message.query === "undefined" ||
        message.query === null ||
        message.query === ""
      ) {
        Toastify("warning", "Query is required!");
      } else if (!validator.isEmail(message.email)) {
        Toastify("warning", "Email is not valid!");
      } else {
        setOpenContactUsProgress(true);
        const res = await axios.post(
          "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/marketing/query",
          {
            ...message,
          }
        );

        if (res.status === 200) {
          setOpenContactUsProgress(false);
          setOpenContactUs(false);
          setmessage({
            name: "",
            email: "",
            query: "",
          });
          Toastify("success", "Message sent !!");
        }
      }
    } catch (error) {
      setOpenContactUs(false);
      setOpenContactUsProgress(false);
      console.log(error, "error");
      Toastify("warning", "Invalid number");
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "rgb(166, 0, 113)",
      },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
    },
  });

  const handleChange = (e) => {
    setmessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  const customSortBasedOnDate = (a, b) => {
    // console.log(a, "a.lauch");
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };
  return (
    <>
      <Dialog disableEscapeKeyDown open={openContactUs}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Send Query
        </DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            className="query-box"
          >
            <ThemeProvider theme={theme}>
              <div className="top-input">
                <TextField
                  error={false}
                  style={{ width: "100%" }}
                  required
                  size="small"
                  label="Name"
                  name="name"
                  value={message.name}
                  onChange={(event) => handleChange(event)}
                />
                <TextField
                  error={false}
                  style={{ width: "100%" }}
                  required
                  size="small"
                  label="Email"
                  name="email"
                  value={message.email}
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <TextField
                error={false}
                style={{ width: "100%" }}
                required
                size="small"
                label="Query"
                name="query"
                fullWidth
                multiline
                minRows={7}
                value={message.query}
                onChange={(event) => handleChange(event)}
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!openContactUsProgress) {
                setOpenContactUs(!openContactUs);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!openContactUsProgress) {
                sendQuery();
              }
            }}
          >
            <div>
              {!openContactUsProgress ? (
                <p>Yes</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
        </DialogActions>
      </Dialog>

      <header className="main_header">
        <NavBar />
        <ActiveBackground />
      </header>

      <div className="blogs-container">
        <div className="header">
          <div className="logo">
            <img
              alt=""
              src={
                "https://lift.lt.partners/static/media/lift_logo_pink-cloud.7b51fda768fdc959c87a.png"
              }
            />
          </div>
          <div className="main-head">
            <h2>LIFT</h2>
            <p>Lift community blogs</p>
          </div>
        </div>
        <hr className="divider1" />
        <div className="blogs-section">
          <h1 className="head">Blogs</h1>
          <div className="blogs-list">
            {blogs.map((b, i) => {
              console.log(b.page_id, "b.page_id");
              return (
                <Link
                  onClick={() => {
                    localStorage.setItem("page_id", b?.page_id);
                  }}
                  to={`/${b.subdomain}/${b.endpoint}`}
                  target="_blank"
                >
                  <div className="blog-container">
                    <div className="img-section">
                      <img
                        alt=""
                        src={
                          b.hero
                            ? b.hero
                            : "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=-1&s=1"
                        }
                      />
                    </div>
                    <div className="content-section">
                      <div className="up">
                        <div className="tag">News</div>
                        <div className="date">{getDate(b.created_at)}</div>
                      </div>
                      <div className="mid">{b.title}</div>
                      <div className="bottom">{b.subtitle} </div>
                      <div className="bottom">5min read</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="Disclaimber">
          <hr />
          <p>
            <span>Disclaimber:</span> Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </p>
          <hr />
        </div>
      </div>

      <Footer setOpenContactUs={setOpenContactUs} />
    </>
  );
};

export default ViewBlogs;
