import { Link } from "react-router-dom";
import List from "./List";
import classes from "./menuBar.module.scss";
import { BsArrowLeft as Icon } from "react-icons/bs";
import { IoClose as Close } from "react-icons/io5";
import { useDispatch } from "react-redux";

const MenuBar2 = (props) => {
  const dispatch = useDispatch();

  // console.log(props.head, "MenuBar")
  // // console.log(nav_tap_nav, "nav_tap_nav")
  // console.log(props.action, "props.action")
  return (
    <nav
      className={` ${props.classMenu} ${classes.container} ${
        props.action ? classes.container_action : ""
      }`}
    >
      <ul>
        <li
          className={classes.goback}
          onClick={() => {
            dispatch(props.clickAction("none"));
          }}
        >
          <List
            goback={<Icon size={30} />}
            sec={0.25}
            headBack={props.head}
            action={props.action}
          />
        </li>
        {props.data.map((info, index) => {
          return (
            <li key={index}>
              {!info?.data?.length ? (
                <Link to={info.link}>
                  <List
                    sec={0.25 + 0.2 * index}
                    icon={info.icon}
                    action={props.action}
                    head={info.head}
                    desc={info.desc}
                  />
                </Link>
              ) : (
                <List
                  nav={info.data}
                  icon={info.icon}
                  sec={0.25 + 0.2 * index}
                  action={props.action}
                  head={info.head}
                  desc={info.desc}
                />
              )}
            </li>
          );
        })}
      </ul>
      <button
        onClick={() => {
          dispatch(props.clickAction("none"));
        }}
        className={classes.close}
      >
        <Close size={25} />
      </button>
    </nav>
  );
};

export default MenuBar2;
