import { Link } from "react-router-dom";
import classes from "./contentbox.module.scss";

const ContentBox = (props) => {
  return (
    <div className={classes.container}>
      <h2>{props.head}</h2>
      <p>{props.content}</p>
      <div className={classes.btn_link}>
        <Link to={"/auth"} className={classes.btn}>
          {props.click}
        </Link>
      </div>
      <img alt="" src={props.img} />
    </div>
  );
};

export default ContentBox;
