const initialState = {
  user_onboard: false,
};

export default (state = initialState, action) => {
  //schedule request

  switch (action.type) {
    case "USER_ONBOARD_EXECUTE":
      state = {
        ...state,
        user_onboard: true,
      };
      break;

    case "USER_ONBOARD_SUCCESS":
      state = {
        ...state,
        user_onboard: false,
      };
      break;
    case "USER_ONBOARD_ERROR":
      state = {
        ...state,
        user_onboard: false,
      };
      break;
  }

  return state;
};
