import classes from "./brandscroll.module.scss";
import { images } from "./images";

const BrandScroll = () => {
  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        Trusted by 60+ forward-thinking companies worldwide!
      </div>
      <div className={classes.brands}>
        <div className={classes.slider}>
          <div className={classes.slider_track}>
            {images.map((d, i) => {
              return (
                <div className={classes.slide} key={i}>
                  <img src={d} alt="" />
                </div>
              );
            })}
          </div>
          <div className={classes.slider_track}>
            {images.map((d, i) => {
              return (
                <div className={classes.slide} key={i}>
                  <img src={d} alt="" />
                </div>
              );
            })}
          </div>
          <div className={classes.slider_track}>
            {images.map((d, i) => {
              return (
                <div className={classes.slide} key={i}>
                  <img src={d} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandScroll;
