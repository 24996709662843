import { Link } from "react-router-dom";
import classes from "./scrollcard.module.scss";

const ScrollCard = (props) => {
  return (
    <div className={classes.card_wrapper}>
      <div className={classes.card}>
        {/* <div className={classes.head}>
                    <p>{props.idx}</p>
                </div> */}
        <div className={classes.conver_show}>
          {/* <div className={classes.image_div}> */}
          <Link>
            <span className={classes.img_span_out}>
              <span className={classes.img_span_in}></span>
              <img src={props.img} alt="" />
            </span>
          </Link>
          <div className={classes.img_overlay}>
            {/* <span>
                            See more
                        </span> */}
          </div>
          {/* </div> */}
        </div>
        <div className={classes.content}>
          <h3>{props.content_head}</h3>

          <div className={classes.content_description}>
            <p>{props.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollCard;
