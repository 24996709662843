import React, { useEffect, useState } from "react";
import { preLoaderAnim } from "./animation";
import "./preloader.scss";
import Spinner from "react-spinner-material";

const PreLoader = () => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (window.scrollY === 0) {
      preLoaderAnim();
    } else {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);
    }
  }, []);

  return (
    <>
      {window.scrollY === 0 ? (
        <div className="preloader">
          <div className="texts-container">
            <span>Lift</span>
            <span>Performance </span>
            <span>Analytics </span>
          </div>
        </div>
      ) : showLoader ? (
        <div className=" loading-suspense">
          <Spinner
            className="spin"
            radius={50}
            color={"white"}
            stroke={3}
            visible={true}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PreLoader;
