import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import FormContainer from "../form-container";
import { ExpandMoreSharp } from "@mui/icons-material";
import "./awin.styles.scss";
import { useEffect, useState } from "react";
import { timeZone } from "../../const/timeZone2";
import { region } from "../../const/region";
import { Toastify } from "../../../../App";
const Awin = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    api_key: "",
    region: "",
    time_zone: "",
    merchant_id: "",
    network_commision_rate: {
      ncr_val: 0,
      ncr_type: "commision",
    },
    adv_mapping: {
      new_cus_type: "",
      repeat_cus_type: "",
      check_in_date: "",
      check_out_date: "",
      night_stayed: "",
      no_of_rooms: "",
    },
    nick_name: "",
  });

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );
    const data = networks_collection?.["awin"];

    if (data !== undefined && data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
        network_commision_rate: {
          ...data?.network_commision_rate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDetailChange = (e) => {
    if (e.target.name === "ncr_val" || e.target.name === "ncr_type") {
      console.log("click");
      setDetails({
        ...details,
        network_commision_rate: {
          ...details.network_commision_rate,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }

    if (
      e.target.name === "new_cus_type" ||
      e.target.name === "repeat_cus_type" ||
      e.target.name === "check_in_date" ||
      e.target.name === "check_out_date" ||
      e.target.name === "night_stayed" ||
      e.target.name === "no_of_rooms"
    ) {
      console.log("click");
      setDetails({
        ...details,

        adv_mapping: {
          ...details.adv_mapping,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const time = timeZone;
  const checkPass = () => {
    if (details.api_key.length === 0 || details.api_key === "") {
      return "API Key required !!";
    } else if (details.region.length === 0 || details.region === "") {
      return "Region required !!";
    } else if (details.time_zone.length === 0 || details.time_zone === "") {
      return "Timezone required !!";
    } else if (details.merchant_id.length === 0 || details.merchant_id === "") {
      return "Merchant Id required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="awin-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="API Key"
          style={{
            margin: "15px 0",
          }}
          name="api_key"
          value={details.api_key}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          select
          required
          size="small"
          label="Region"
          style={{
            margin: "15px 0",
          }}
          value={details.region}
        >
          {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
          {/* {console.log(orgs, "orgs")} */}
          <MenuItem
          // onClick={() => {
          //   setInviteOrgData(null);
          // }}
          // value={0}
          >
            Please select a region..
          </MenuItem>
          {region.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "region",
                      value: t.option,
                    },
                  });
                }}
                value={t.option}
              >
                {t.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          select
          required
          size="small"
          label="Timezone"
          style={{
            margin: "15px 0",
          }}
          value={details.time_zone}
        >
          {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
          {/* {console.log(orgs, "orgs")} */}
          <MenuItem
          // onClick={() => {
          //   setInviteOrgData(null);
          // }}
          // value={0}
          >
            Please select a timezone..
          </MenuItem>
          {time.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "time_zone",
                      value: t.time,
                    },
                  });
                }}
                value={t.time}
              >
                {t.time}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          required
          size="small"
          label="Merchant ID"
          style={{
            margin: "15px 0",
          }}
          name="merchant_id"
          value={details.merchant_id}
          onChange={(event) => onDetailChange(event)}
        />
        <div className="number-and-option">
          <TextField
            error={false}
            size="small"
            label="Network Commission Rate (Optional)"
            type="number"
            style={{
              width: "100%",
              margin: "15px 0",
            }}
            name="ncr_val"
            value={details.network_commision_rate.ncr_val}
            onChange={(event) => onDetailChange(event)}
          />
          <div
            style={{ fontSize: "1rem", fontWeight: "500", padding: "0 10px" }}
          >
            {" "}
            Of{" "}
          </div>
          <ButtonGroup
            style={{
              width: "100%",
            }}
          >
            <Button
              variant={
                details.network_commision_rate.ncr_type === "commision"
                  ? "contained"
                  : "outlined"
              }
              onClick={(event) =>
                onDetailChange({
                  target: {
                    name: "ncr_type",
                    value: "commision",
                  },
                })
              }
            >
              Commision
            </Button>
            <Button
              variant={
                details.network_commision_rate.ncr_type === "revenue"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                onDetailChange({
                  target: {
                    name: "ncr_type",
                    value: "revenue",
                  },
                })
              }
            >
              Revenue
            </Button>
          </ButtonGroup>
        </div>
        <Accordion style={{ margin: "15px 0" }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            <span className="accordion-title">Advance Mapping</span>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              error={false}
              required
              size="small"
              label="New Customer Type"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="new_cus_type"
              value={details?.adv_mapping?.new_cus_type}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              error={false}
              required
              size="small"
              label="Repeate Customer Type"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="repeat_cus_type"
              value={details?.adv_mapping?.repeat_cus_type}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              error={false}
              required
              size="small"
              label="Check In Date"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="check_in_date"
              value={details?.adv_mapping?.check_in_date}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              error={false}
              required
              size="small"
              label="Check Out Date"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="check_out_date"
              value={details?.adv_mapping?.check_out_date}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              error={false}
              required
              size="small"
              label="Night Stayed"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="night_stayed"
              value={details?.adv_mapping?.night_stayed}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              error={false}
              required
              size="small"
              label="Number of Rooms"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="no_of_rooms"
              value={details?.adv_mapping?.no_of_rooms}
              onChange={(event) => onDetailChange(event)}
            />
          </AccordionDetails>
        </Accordion>
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect(details, "awin");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Awin;
