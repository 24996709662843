import { Button, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./admitad.styles.scss";
import { useEffect, useState } from "react";
import { Toastify } from "../../../../App";
const Admitad = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    client_id: "",
    api_secrect: "",
    nick_name: "",
  });

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );
    const data = networks_collection?.["admitad"];

    if (data !== undefined && data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.client_id.length === 0 || details.client_id === "") {
      return " Client ID  required !!";
    } else if (details.api_secrect.length === 0 || details.api_secrect === "") {
      return "API Secrect required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="admitad-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="Client ID"
          style={{
            margin: "15px 0",
          }}
          name="client_id"
          value={details.client_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          required
          size="small"
          label="API Secrect"
          style={{
            margin: "15px 0",
          }}
          type="password"
          name="api_secrect"
          value={details.api_secrect}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          console.log("click");
          Connect(details, "admitad");
        }}
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Admitad;
