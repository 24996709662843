import { Link } from "react-router-dom";
import classes from "./accordion2.module.scss";
import { useState } from "react";
import img1 from "../../../../assests/compo/Accordian2/1.webp";
import img2 from "../../../../assests/compo/Accordian2/2.webp";
import img3 from "../../../../assests/compo/Accordian2/3.webp";
import img4 from "../../../../assests/compo/Accordian2/4.webp";

const info = [
  {
    text: "Executive Home Page",
  },
  {
    text: "Profile Tabs",
  },
  {
    text: "New Publisher Comp.",
  },
  {
    text: "New Network vs Google Analytics Comp.",
  },
];

const cards = [
  {
    head1: "1. Executive Home Page",
    head2: "High level overview of brand performance",
    content:
      "You can now find the monthly metrics performance, top performers, metric totals, best score based publishers and last placement analysis; all in a single dashboard!",
    img: img1,
  },
  {
    head1: "2. Profile Tabs",
    head2: "Granular control on your Brand - LIFT connection",
    content:
      "You can now change default weights of your brand’s scorecard, update brand details, change your avatar, and much more inside the profile section.",
    img: img2,
  },
  {
    head1: "3. New Publisher Comparison",
    head2: "Improved, extensive and varied comparison",
    content:
      "You can now compare publishers’ performance against last week, month and year. And in case that’s still not enough, you can compare it against a custom period as well. Plus, you can SORT comparison columns now!",
    img: img3,
  },
  {
    head1: "4. New Network vs Google Analytics Comparison",
    head2: "Improved precision of revenue metrics",
    content:
      "We have revamped our data transformation pipeline and updated our merging capabilities to bring you more precise metrics for comparison of revenue reported by Google Analytics and your network.",
    img: img4,
  },
];

const CardDiv = (props) => {
  return (
    <div className={classes.card}>
      <div className={classes.card_wrapper}>
        <div className={classes.content}>
          <h4>{props.head1}</h4>
          <h2>{props.head2}</h2>
          <p>{props.content}</p>
          <div className={classes.height_cls}></div>
        </div>
        <div className={classes.image}>
          <img alt="" src={props.img} />
        </div>
      </div>
    </div>
  );
};

const Accordian2 = () => {
  const [active, setActive] = useState(0);
  const [act, setAction] = useState(false);

  // let idx = 0
  // useEffect(() => {
  //   let idx = active;
  //   var interval = setInterval(increment, 5000);
  //   function increment() {
  //     idx = idx + 1;
  //     if (idx === stop) {
  //       idx = 0;
  //     }
  //     setActive(idx);
  //   }

  //   return () => clearInterval(interval);
  // }, [act]);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <h2 className={classes.head1}>What's new ?</h2>
          <h2 className={classes.head2}>Our latest feature updates.</h2>
          <p className={classes.para}>
            We’re constantly improving LIFT to provide the new features, better
            UI and improved user experience for our users. Check out our new
            feature updates, each aimed to make your life easy.
          </p>
          <div className={classes.tab_container}>
            <div className={classes.tabs}>
              {info.map((d, i) => {
                return (
                  <Link
                    key={i}
                    className={`${active === i ? classes.active_tab_link : ""}`}
                    onClick={() => {
                      setActive(i);
                      setAction(!act);
                    }}
                  >
                    <div>
                      <span>{d.text}</span>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className={classes.tab_contents}>
              {cards.map((d, i) => {
                return active === i ? (
                  <div className={classes.card_container} key={i}>
                    <CardDiv
                      key={i}
                      img={d.img}
                      head1={d.head1}
                      head2={d.head2}
                      content={d.content}
                    />
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          </div>
          {/* <div class={classes.link}>
            <Link>
              Browser more{" "}
              <span>
                <HiArrowRight />{" "}
              </span>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Accordian2;
