import classes from "./activeBackground.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  menu_action,
  navbar_actions,
  navbar_actions1,
} from "../../redux/actions";

const ActiveBackground = (props) => {
  const nav_tab = useSelector((state) => state.nav_bar);
  const dispatch = useDispatch();
  return (
    <span
      onClick={() => {
        dispatch(navbar_actions("none"));
        dispatch(navbar_actions1("none"));
        dispatch(menu_action(!nav_tab.menu));
      }}
      role="button"
      className={` ${nav_tab.menu ? classes.container_open : ""} ${
        classes.container
      } ${nav_tab.nav_tab !== "none" ? classes.container_active : ""}`}
    ></span>
  );
};

export default ActiveBackground;
