import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Button, AppBar, Toolbar } from "@mui/material";
import axios from "axios";
import Lottie from "react-lottie";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import * as animData from "./lf30_editor_lf4h7tsf.json";

import "./waiting.styles.scss";
import { Toastify } from "../../App";

// Status(s)
// 1 Not verified by admin or self
// 2 Verified by admin but not self
// 3 Not verified by admin and verified by self
// 4 On Hold

const Waiting = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [status, setStatus] = useState(1);

  useEffect(() => {
    if (state?.registration_user === true) {
      console.log(state, "user");
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   checkStatus();
  // });

  const checkStatus = () => {
    let id = state?.id;

    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/status/${state.id}`,
        {
          headers: {
            Authorization: `JWT ${state.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);

        if (response.data.verified === 1) {
          axios
            .get(
              `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/details/${state.id}`,
              {
                headers: {
                  Authorization: `JWT ${state.token}`,
                },
              }
            )
            .then((res) => {
              // navigate(`/report${res.data.brand.split(",")[0]}`);

              localStorage.setItem(
                "user",
                JSON.stringify({
                  name: response.data.name,
                  id: response.data.id,
                  token: state.token,
                })
              );

              window.location.assign(
                `https://app.lift.lt.partners/testload?_id=${
                  response.data.id
                }&token=${state.token}&onboard=${1}`
              );
            })
            .catch((err) => console.error(err));
        } else if (
          response.data.verified === 1 &&
          response.data.admin_verified === 0
        )
          setStatus(3);
        else if (
          response.data.verified === 0 &&
          response.data.admin_verified === 1
        )
          setStatus(2);
        else if (
          response.data.verified === 1 &&
          response.data.admin_verified === 2
        )
          setStatus(4);
        else {
          setStatus(1);
          Toastify(
            "error",
            "Email verfication is required. Please Check your email"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className="waiting">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          zLayers: 2,
          fpsLimit: 30,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "rgb(166, 0, 113)",
            },
            links: {
              color: "rgb(166, 0, 113)",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 6,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 40,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <div className="web">
        <Paper className="card">
          <div className="anim">
            <Lottie
              options={{
                animationData: animData,
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={350}
              width={350}
            />
          </div>
          <div className="main-text">
            {status === 1 || status === 2
              ? "Please verify your email to continue!"
              : status === 4
              ? "Your account has been put on hold! We will notify you of any future changes."
              : "Please wait while admin verifies your account details!"}
          </div>
          <Button className="check-btn" onClick={checkStatus}>
            Check again
          </Button>
          <p className="query-txt">
            In case of any queries.{" "}
            <a
              className="mail-txt"
              href="mailto:ltp-analytics-admin@lt.partners"
            >
              Write a mail here.
            </a>
          </p>
        </Paper>
      </div>
      <div className="mobile">
        {/* Top Section */}
        <AppBar position="static" className="appbar">
          <Toolbar variant="regular" className="top">
            <img
              className="logo"
              alt="Logo"
              src={process.env.PUBLIC_URL + "/assets/ltp_fav.png"}
            />
          </Toolbar>
        </AppBar>

        <Lottie
          options={{
            animationData: animData,
            loop: true,
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={300}
          width={300}
        />
        <div className="main-text">
          {status === 1 || status === 2
            ? "Please verify your email to continue!"
            : status === 4
            ? "Your account has been put on hold! We will notify you of any future changes."
            : "Please wait while admin verifies your account details!"}
        </div>
        <Button className="check-btn" onClick={checkStatus}>
          Check again
        </Button>
        <p className="query-txt">
          In case fo any queries.{" "}
          <a className="mail-txt" href="mailto:ltp-analytics-admin@lt.partners">
            Write a mail here.
          </a>
        </p>

        <div className="foot">
          <p>We recommend using this application in your Laptop or Tablet</p>
        </div>
      </div>
    </div>
  );
};

export default Waiting;
