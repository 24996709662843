import ContentBox from "./ContentBox";
import classes from "./contentsection.module.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import img1 from "../../../../assests/compo/ContentSection/1.webp";
import img2 from "../../../../assests/compo/ContentSection/2.webp";
import img3 from "../../../../assests/compo/ContentSection/3.webp";
import img4 from "../../../../assests/compo/ContentSection/4.webp";
import { useDispatch } from "react-redux";
import { content_ref_action } from "../../../../redux/actions";

const data = [
  {
    head: "Evaluate publishers with our proprietary scoring system",
    content:
      "Find and negotiate with publishers that cater to your needs with our proprietary scoring system.",
    click: "Get started",
    img: img1,
  },
  {
    head: "Analyze performance of your placements",
    content:
      "Better understand the incrementality of a placement in terms of revenue, conversion rate, new users and more.",
    click: "Get started",
    img: img2,
  },
  {
    head: "Publisher’s performance",
    content:
      "Understand how publishers are performing against different periods in terms of Revenue, CR, ROAS, and more.",
    click: "Get started",
    img: img3,
  },
  {
    head: "Find the top performing publishers and channels",
    content:
      "Figure out which publishers and channels are performing best in terms of revenue, traffic, and more.",
    click: "Get started",
    img: img4,
  },
];

const imgs = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
];

const ContentSection = (props) => {
  const [divpost, setDivPos] = useState(0);

  const changeImgOnScroll = () => {
    const el1 = document.querySelector("#slider_view_content");
    const el2 = document.querySelector("#stick_container");
    const top1 = el1?.getBoundingClientRect()?.top;
    const top2 = el2?.getBoundingClientRect()?.top;
    let fact = 123.99057006835938;

    // let divpos = Math.floor((top2 - top1 - fact) /781.8325805664062)

    // console.log(Math.round(Math.round(top2 - top1 - fact + 100) / 798), "test1")
    // console.log(Math.round((bottom - top) / 4), "test2");
    setDivPos(Math.round(Math.round(top2 - top1 - fact + 100) / 798));
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // handleScroll();
      changeImgOnScroll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });
  }, []);

  const contentRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(content_ref_action(contentRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef]);

  return (
    <div className={classes.content_section_container} ref={contentRef}>
      <h1 className={classes.content_section_head}>
        LIFT helps you manage your affiliate program backed by our proprietary
        analytic reports.
      </h1>
      <div id="slider_view_content" className={classes.inner_container}>
        <div className={classes.content_text}>
          {data.map((d, i) => {
            return (
              <Fragment key={i}>
                <ContentBox
                  img={d.img}
                  head={d.head}
                  content={d.content}
                  click={d.click}
                />
              </Fragment>
            );
          })}
        </div>
        <div className={classes.graphic_container}>
          <div id="stick_container" className={classes.img_container}>
            {/* <div> */}
            <div className={classes.img_ss}>
              <img
                className={classes.img_1}
                alt=""
                src={
                  "https://uploads-ssl.webflow.com/61ffe6be3bd15a4f5d7b3986/6214ca869b3bcd57f3258e97_build.jpg"
                }
              />
              {imgs.map((d, i) => {
                return (
                  <img
                    key={i}
                    alt=""
                    className={`image_${i + 1} ${classes.img_other}`}
                    style={{ opacity: divpost === i ? 1 : 0 }}
                    src={d.img}
                  />
                );
              })}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
