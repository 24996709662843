import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";

import "./auth-form-input.scss";

const AuthFormInput = ({ label, type, hint, value, changeFunction, error }) => {
  const [passwordUpdate, setPasswordUpdate] = useState({
    current: "password",
  });

  const handlePassword = (type) => {
    passwordUpdate[type] =
      passwordUpdate[type] === "text" ? "password" : "text";
    setPasswordUpdate({
      ...passwordUpdate,
    });
  };
  if (type === "checkbox") {
    return (
      <div>
        <div className="input-form-check">
          <p className="label">{label}</p>
          <input
            className={error.length <= 0 ? "field" : "field-with-err"}
            type={"checkbox"}
            placeholder={hint}
            value={value}
            onChange={changeFunction}
          />
        </div>
        {error.length <= 0 ? (
          ""
        ) : (
          <div className="err-space">
            <p className="err-text-field">{error}</p>
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <div className="input-form">
        <p className="label">{label}</p>
        {/* <input
          className={error.length <= 0 ? "field" : "field-with-err"}
          type={type}
          placeholder={hint}
          value={value}
          onChange={changeFunction}
        /> */}
        {type === "password" ? (
          <>
            <input
              className={error.length <= 0 ? "field" : "field-with-err"}
              type={passwordUpdate.current}
              // name={name}
              placeholder={hint}
              value={value}
              onChange={changeFunction}
            />

            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                handlePassword("current");
              }}
              // onMouseDown={handleMouseDownPassword}
            >
              {passwordUpdate.current === "password" ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </>
        ) : (
          <>
            <input
              className={error.length <= 0 ? "field" : "field-with-err"}
              type={type}
              // name={name}
              placeholder={hint}
              value={value}
              onChange={changeFunction}
            />
            <IconButton
              aria-label="toggle password visibility"
              style={{ visibility: "hidden" }}
            >
              {passwordUpdate.current === "password" ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </>
        )}
      </div>
      {error.length <= 0 ? (
        ""
      ) : (
        <div className="err-space">
          <p className="err-text-field">{error}</p>
        </div>
      )}
    </div>
  );
};

export default AuthFormInput;
