import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import Spinner from "react-spinner-material";
import validator from "validator";
import axios from "axios";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import "./login.scss";

import AuthFormInput from "../auth-form-input";
import { Toastify } from "../../App";
// import { app } from "../../utils/firebase-config";

const Login = ({ changeUserAction }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showLoading, setShowLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setCheck(
      location.state === null || location.state === "login"
        ? false
        : location.state?.type === 1
        ? true
        : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = () => {
    localStorage.clear();

    if (email == null || email === "") {
      setEmailError("Required!");
      setPasswordError("");
      return;
    } else if (password == null || password === "") {
      setEmailError("");
      setPasswordError("Required!");
      return;
    } else if (!validator.isEmail(email)) {
      setEmailError("Invalid Email!");
      setPasswordError("");
      return;
    } else {
      setEmailError("");
      setPasswordError("");
      setShowLoading(true);

      // Login User
      const requestBody = {
        email: email,
        password: password,
      };
      axios
        .post(
          "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/login",
          requestBody
        )
        .then((response) => {
          console.log(response.data, "response.data");
          if (response.data.verified === 1) {
            // what about this ?
            localStorage.setItem(
              "user",
              JSON.stringify({
                name: response.data.name,
                id: response.data.id,
                token: response.data.token,
              })
            );

            //https://fascinating-gumption-6bea9b.netlify.app
            if (check) {
              window.location.assign(
                `https://app.lift.lt.partners/testload?_id=${response.data.id}&token=${response.data.token}`
              );
            } else {
              window.location.assign(
                `https://app.lift.lt.partners/testload?_id=${
                  response.data.id
                }&token=${response.data.token}&onboard=${1}`
              );
            }
          } else {
            // navigate("/waiting", {
            //   state: {
            //     id: response.data.id,
            //     name: response.data.name,
            //     email: email,
            //     brand: response.data.brand,
            //     image: response.data.image,
            //     track: response.data.track_usage,
            //     team: response.data.team,
            //   },
            // });
            setShowLoading(false);
            Toastify(
              "error",
              "Email verfication is required. Please Check your email"
            );
          }
        })
        .catch((err) => {
          setShowLoading(false);

          if (err.response.data.message === "Wrong Password") {
            setEmailError("");
            setPasswordError(err.response.data.message);
            return;
          } else {
            setEmailError(err.response.data.message);
            setPasswordError("");
            return;
          }
        });
    }
  };

  return (
    <div className="login-component">
      <p className="title-login">Log in</p>
      <p className="title-login-tag">
        Get access to exclusive analytics by LT Partners
      </p>
      <AuthFormInput
        label="Email"
        type="email"
        hint="jane@lt.partners"
        value={email}
        changeFunction={(event) => setEmail(event.target.value)}
        error={emailError}
      />
      <AuthFormInput
        label="Password"
        type="password"
        hint="paS5w0rD"
        value={password}
        changeFunction={(event) => setPassword(event.target.value)}
        error={passwordError}
      />

      {/* <AuthFormInput
        label="Lorem ipsum dolor sit amet consectetur"
        type="checkbox"
        // hint="paS5w0rD"
        value={check}
        changeFunction={(event) => {
          // test(event.target.checked);
          setCheck(event.target.checked);
        }}
        error={checkError}
      /> */}

      <Button className="login-btn" onClick={handleSubmit}>
        {showLoading ? (
          <div className="loading">
            <Spinner
              className="spin"
              radius={23}
              color={"white"}
              stroke={3}
              visible={true}
            />
            <div className="spacer">Loading</div>
          </div>
        ) : (
          "Log In"
        )}
      </Button>
      <br />
      {!check ? (
        <>
          <div className="forgot-pwd-text">
            <p
              className="forgot-pwd-text-child"
              onClick={() => changeUserAction("forgot-pwd")}
            >
              Forgot Password ?
            </p>
          </div>
          {/* <p className="new">
            Don't have an account?{" "}
            <span className="reg-link" onClick={() => changeUserAction("reg")}>
              Sign Up
            </span>
          </p> */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Login;
