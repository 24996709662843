import { navbar_constant } from "../const";
export const navbar_actions = (nav_tab) => {
  // console.log(nav_tab, "nav_tab")
  return async (dispatch) => {
    try {
      dispatch({
        type: navbar_constant.NAV_TAB_ACTION,
        payload: {
          nav_tab: nav_tab,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.NAV_TAB_ACTION_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const navbar_actions1 = (nav_tap_nav) => {
  // console.log(nav_tap_nav, "nav_tap_nav")
  return async (dispatch) => {
    try {
      dispatch({
        type: navbar_constant.NAV_TAB_ACTION1,
        payload: {
          nav_tap_nav: nav_tap_nav,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.NAV_TAB_ACTION_ERROR1,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const menu_action = (action) => {
  return async (dispatch) => {
    try {
      console.log(action, "acton");
      dispatch({
        type: navbar_constant.MENU_ACTION,
        payload: {
          menu: action,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.MENU_ACTION_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const price_ref_action = (priceRef) => {
  return async (dispatch) => {
    try {
      // console.log(priceRef, "acton");
      dispatch({
        type: navbar_constant.PRICE_REF_ACTION,
        payload: {
          priceRef: priceRef,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.PRICE_REF_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const horizontal_ref_action = (horizontalRef) => {
  return async (dispatch) => {
    try {
      // console.log(horizontalRef, "acton");
      dispatch({
        type: navbar_constant.HORIZONTAL_REF_ACTION,
        payload: {
          horizontalRef: horizontalRef,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.HORIZONTAL_REF_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const integration_ref_action = (integrationRef) => {
  return async (dispatch) => {
    try {
      // console.log(integrationRef, "acton");
      dispatch({
        type: navbar_constant.INTEGRATION_REF_ACTION,
        payload: {
          integrationRef: integrationRef,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.INTEGRATION_REF_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const content_ref_action = (contentRef) => {
  return async (dispatch) => {
    try {
      // console.log(integrationRef, "acton");
      dispatch({
        type: navbar_constant.CONTENT_REF_ACTION,
        payload: {
          contentRef: contentRef,
        },
      });
    } catch (error) {
      dispatch({
        type: navbar_constant.CONTENT_REF_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};
