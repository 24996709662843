import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FormContainer from "../form-container";
import { ExpandMoreSharp } from "@mui/icons-material";
import "./commision-junction.styles.scss";
import { Toastify } from "../../../../App";
import { useEffect, useState } from "react";

const CommisionJunction = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    username: "",
    password: "",
    placement: {
      option1: "yes",
      option2: "Split evenly over each day of the event (Default)",
    },
    nick_name: "",
  });

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );
    const data = networks_collection?.["commission-junction"];

    if (data !== undefined && data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDetailChange = (e) => {
    if (e.target.name === "option1" || e.target.name === "option2") {
      console.log("click");
      setDetails({
        ...details,
        placement: {
          ...details.placement,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.username.length === 0 || details.username === "") {
      return "Username required !!";
    } else if (details.password.length === 0 || details.password === "") {
      return "Currency required !!";
    }
    return "";
  };

  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="commision-factory-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="Username"
          style={{
            margin: "15px 0",
          }}
          name="username"
          value={details.username}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          required
          size="small"
          label="Password"
          style={{
            margin: "15px 0",
          }}
          type="password"
          name="password"
          value={details.password}
          onChange={(event) => onDetailChange(event)}
        />
        <Accordion style={{ margin: "15px 0" }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            <span className="accordion-title">
              Placement Configuration (Advance)
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Please contact support if you've enabled and configured placements
              in Affluent and need to change the configuration.
            </p>
            <FormControl sx={{ m: 3 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Include placement data?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                value={details?.placement?.option1}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes (Default)"
                  onClick={() => {
                    onDetailChange({
                      target: {
                        value: "yes",
                        name: "option1",
                      },
                    });
                  }}
                />
                <FormControlLabel
                  onClick={() => {
                    onDetailChange({
                      target: {
                        value: "no",
                        name: "option1",
                      },
                    });
                  }}
                  value="no"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ m: 3 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                What data should placement fees be recorded?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Split evenly over each day of the event (Default)"
                name="radio-buttons-group"
                value={details?.placement?.option2}
              >
                <FormControlLabel
                  value="Split evenly over each day of the event (Default)"
                  control={<Radio />}
                  label="Split evenly over each day of the event (Default)"
                  onClick={() => {
                    onDetailChange({
                      target: {
                        value:
                          "Split evenly over each day of the event (Default)",
                        name: "option2",
                      },
                    });
                  }}
                />
                <FormControlLabel
                  value="Start of the event"
                  control={<Radio />}
                  label="Start of the event"
                  onClick={() => {
                    onDetailChange({
                      target: {
                        value: "Start of the event",
                        name: "option2",
                      },
                    });
                  }}
                />
                <FormControlLabel
                  value="End of the event"
                  control={<Radio />}
                  label="End of the event"
                  onClick={() => {
                    onDetailChange({
                      target: {
                        value: "End of the event",
                        name: "option2",
                      },
                    });
                  }}
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect(details, "commission-junction");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default CommisionJunction;
