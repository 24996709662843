import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./ever-flow.styles.scss";
import { timeZone } from "../../const/timeZone";
import { useEffect, useState } from "react";
import { Toastify } from "../../../../App";
const EverFlow = ({ Connect, currentNetImg }) => {
  const time = timeZone;
  const [details, setDetails] = useState({
    api_key: "",
    time_zone: "",
    nick_name: "",
  });

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );
    const data = networks_collection?.["ever-flow"];

    if (data !== undefined && data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
        network_commision_rate: {
          ...data?.network_commision_rate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.api_key.length === 0 || details.api_key === "") {
      return "API Key required !!";
    } else if (details.time_zone.length === 0 || details.time_zone === "") {
      return "Time Zone required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="ever-flow-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="API key"
          style={{
            margin: "15px 0",
          }}
          name="api_key"
          value={details.api_key}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          select
          required
          size="small"
          label="Select a timezone (usually GMT)"
          style={{
            margin: "15px 0",
          }}
          value={details.time_zone}
        >
          <MenuItem>Please select a timezone..</MenuItem>
          {time.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "time_zone",
                      value: t.time,
                    },
                  });
                }}
                value={t.time}
              >
                {t.time}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect(details, "ever-flow");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default EverFlow;
