import React, { useEffect, useRef, useState } from "react";
import OnBoardTab from "../../components/onboarding/onboarding-tabs";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./onboarding.styles.scss";
import WestIcon from "@mui/icons-material/West";
import validator from "validator";
import Pricing from "../../components/pricing";
import Upload from "../../components/image-input";
import {
  TextField,
  createTheme,
  CircularProgress,
  SwipeableDrawer,
  Dialog,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Payment from "../../components/onboarding/payment";
import { useDispatch, useSelector } from "react-redux";
import { keys } from "../../keys";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Impact from "./forms/impact/impact";
import Admitad from "./forms/admitad/admitad";
import AdTraction from "./forms/ad-traction/ad-traction";
import AffiliateFuture from "./forms/affiliate-future/affiliate-future";
import AvantLink from "./forms/avant-link/avant-link";
import DaisyCon from "./forms/daisy-con/daisy-con";
import EverFlow from "./forms/ever-flow/ever-flow";
import LinkConnector from "./forms/link-connector/link-connector";
import Odyssey from "./forms/odyssey/odyssey";
import OneNetworkDirect from "./forms/one-network-direct/one-network-direct";
import PaidOnResults from "./forms/paid-on-results/paid-on-results";
import Partnerize from "./forms/partnerize/partnerize";
import PartnerStack from "./forms/partner-stack/partner-stack";
import PepperJam from "./forms/pepper-jam/pepper-jam";
import RakutenLinkshare from "./forms/rakuten-linkshare/rakuten-linkshare";
import Refersion from "./forms/refersion/refersion";
import ShareASale from "./forms/share-a-sale/share-a-sale";
import TradeTracker from "./forms/trade-tracker/trade-tracker";
import Tune from "./forms/tune/tune";
import WebGains from "./forms/web-gains/web-gains";
import TradeDoubler from "./forms/trade-doubler/trade-doubler";
import CommisionFactory from "./forms/commission-factory/commision-factory";
import CommisionJunction from "./forms/commision-junction/commision-junction";
import Awin from "./forms/awin/awin";
import { UnSetCoupon } from "../../redux/actions/payment.action";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Toastify } from "../../App";
import axios from "axios";
// import { logEvent } from "@firebase/analytics";
import PasswordValidator from "password-validator";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const OnBoarding = () => {
  const payment = useSelector((state) => state.payment);
  const { invite_token } = useParams();

  const [invitation, setInvitation] = useState({
    name: "",
    email: "",
    pwd: "",
    re_pwd: "",
    position: "",
    org_name: "",
    org_img: "",
    network: "",
    payment: "",
  });

  // password visibility
  const [passwordUpdate, setPasswordUpdate] = useState({
    current: "password",
    confirm: "password",
  });

  const handlePassword = (type) => {
    passwordUpdate[type] =
      passwordUpdate[type] === "text" ? "password" : "text";
    setPasswordUpdate({
      ...passwordUpdate,
    });
  };
  // password visibility

  //payment
  const [showPaymentDialog, SetShowPaymentDialog] = useState(false);
  const sub_click = React.useRef();
  const [subscribed, setSubscribed] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentRequired, setPaymentRequired] = useState(null);
  //payment

  // get intivation
  // const getInvitation = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/invite/details?token=${invite_token}`
  //     );

  //     const invitationinfo = JSON.parse(localStorage.getItem("invitationInfo"));

  //     if (
  //       res.data?.details === undefined ||
  //       res.data?.details === null ||
  //       res.data?.details === {}
  //     ) {
  //       Toastify("error", res.data?.message);
  //     } else {
  //       setSubscribed(res.data.organization_subscription);
  //       setPaymentRequired(res.data.payment_required);
  //       setPaymentDetails(res.data.payment_details);

  //       if (
  //         invitationinfo !== null &&
  //         invitationinfo !== undefined &&
  //         invitationinfo !== ""
  //       ) {
  //         delete invitationinfo.admin;

  //         localStorage.setItem(
  //           "invitationInfo",
  //           JSON.stringify({
  //             ...invitationinfo,
  //             email: res.data.details.email,
  //             name: res.data.details.name,
  //           })
  //         );

  //         setInvitation({
  //           ...invitationinfo,
  //           admin: {
  //             ...res.data.details,
  //           },
  //         });
  //       } else {
  //         localStorage.setItem(
  //           "invitationInfo",
  //           JSON.stringify({
  //             pwd: "",
  //             re_pwd: "",
  //             payment: "",
  //             email: res.data.details.email,
  //             name: res.data.details.name,
  //           })
  //         );

  //         setInvitation({
  //           ...invitation,
  //           email: res.data.details.email,
  //           name: res.data.details.name,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);

  //     Toastify("error", error.response?.data?.message);
  //   }
  // };

  const get_ref = useRef(true);
  React.useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      // logEvent(analytics, "onboading_page_visited");
      // getInvitation();
    }

    let stage = JSON.parse(localStorage.getItem("stage"));
    if (stage !== null && stage !== undefined && stage !== "") {
      setOpen(stage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = React.useState(0);
  // get intivation

  // React.useEffect(() => {
  //   let stage = JSON.parse(localStorage.getItem("stage"));
  //   if (stage != null && stage !== undefined && stage !== "") {
  //     setOpen(stage);
  //   }
  // }, []);
  // const [open, setOpen] = React.useState(2);

  // get intivation
  const [details, setDetails] = React.useState({
    name: "",
    email: "",
    pwd: "",
    re_pwd: "",
    position: "",
    org_name: "",
    org_img: "",
    network: "",
    payment: "",
  });

  const [detailsCheck, setDetailsCheck] = React.useState({
    name: "",
    email: "",
    pwd: "",
    re_pwd: "",
    position: "",
    org_name: "",
    org_img: "",
    network: "",
    payment: "",
  });

  React.useState(() => {
    const info = JSON.parse(localStorage.getItem("invitationInfo"));

    if (info !== null && info !== undefined) {
      setInvitation({
        ...info,
      });
    }
  }, [details]);

  const changeDetails = (e) => {
    if (e.target.name === "org_img") {
      const file = e.target.files[0];
      const newdata = {
        ...invitation,
        [e.target.name]: URL.createObjectURL(file),
      };
      setInvitation(newdata);
      localStorage.setItem("invitationInfo", JSON.stringify(newdata));

      return;
    }

    const newdata = {
      ...invitation,
      [e.target.name]: e.target.value,
    };

    setInvitation(newdata);

    localStorage.setItem("invitationInfo", JSON.stringify(newdata));
  };

  React.useEffect(() => {
    checkStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation]);

  const open1 = ["name", "email", "pwd", "re_pwd", "position"];
  const open2 = [
    "org_name",
    // 'prg_img',
  ];
  // const open3 = [];
  const data = [
    {
      icon: <PersonOutlineIcon />,
      type_content: "User's Detail",
    },
    {
      icon: <CorporateFareIcon />,
      type_content: "Organization",
    },
    {
      icon: <BrandingWatermarkIcon />,
      type_content: "Affiliate account details",
    },
    {
      icon: <AttachMoneyIcon />,
      type_content: "Payment",
    },
  ];

  const networks = [
    {
      title: "Impact",
      image: "https://www.affluent.io/images/platforms/impact-logo.jpg",
      net: "impact",
      checked: false,
    },
    // {
    //   title: "Google Analytics",
    //   image: "https://www.affluent.io/images/platforms/google-analytics.png",
    //   checked: true,
    // },
    {
      title: "Admitad",
      image: "https://www.affluent.io/images/platforms/admitad.png",
      net: "admitad",
      checked: false,
    },
    {
      title: "AdTraction",
      image: "https://www.affluent.io/images/platforms/adtraction-logo.jpg",
      net: "ad-traction",
      checked: false,
    },
    {
      title: "Affiliate Future",
      image: "https://www.affluent.io/images/platforms/affiliatefuture.png",
      checked: false,
      net: "affiliate-future",
    },
    {
      title: "AvantLink",
      image: "https://www.affluent.io/images/platforms/avantlink-logo.png",
      checked: false,
      net: "avant-link",
    },
    {
      title: "Awin",
      image:
        "https://www.affluent.io/images/platforms/affiliate-window-logo.jpg",
      checked: false,
      net: "awin",
    },
    {
      title: "Commissionfactory",
      image: "https://www.affluent.io/images/platforms/comfact.png",
      checked: false,
      net: "commission-factory",
    },
    {
      title: "Commision Junction",
      image:
        "https://www.affluent.io/images/platforms/commission-junction-logo.jpg",
      checked: false,
      net: "commission-junction",
    },
    {
      title: "DaisyCon",
      image: "https://www.affluent.io/images/platforms/daisy.png",
      checked: false,
      net: "daisy-con",
    },
    {
      title: "Everflow",
      image: "https://www.affluent.io/images/platforms/everflow-logo.png",
      checked: false,
      net: "ever-flow",
    },
    {
      title: "LinkConnector",
      image: "https://www.affluent.io/images/platforms/linkconnector.png",
      checked: false,
      net: "link-connector",
    },
    {
      title: "Odyssey",
      image: "https://www.affluent.io/images/platforms/odyssey-logo.png",
      checked: false,
      net: "odyssey",
    },
    {
      title: "oneNetwrokDirect",
      image: "https://www.affluent.io/images/platforms/ond.png",
      checked: false,
      net: "one-network-direct",
    },
    {
      title: "Paid On Results",
      image: "https://www.affluent.io/images/platforms/paidon.png",
      checked: false,
      net: "paid-on-results",
    },
    {
      title: "Partnerize",
      image: "https://www.affluent.io/images/platforms/partnerize-logo.png",
      checked: false,
      net: "partnerize",
    },
    {
      title: "PartnerStack",
      image: "https://www.affluent.io/images/platforms/partnerstack-logo.png",
      checked: false,
      net: "partner-stack",
    },
    {
      title: "Pepperjam",
      image: "https://www.affluent.io/images/platforms/pepperjam-logo.jpg",
      checked: false,
      net: "pepper-jam",
    },
    {
      title: "Rakuten LinkShare",
      image: "https://www.affluent.io/images/platforms/linkshare-logo.jpg",
      checked: false,
      net: "rakuten-linkshare",
    },
    {
      title: "Refersion",
      image: "https://www.affluent.io/images/platforms/refersion-logo.png",
      checked: false,
      net: "refersion",
    },
    {
      title: "ShareASale",
      image: "https://www.affluent.io/images/platforms/shareasale-logo.jpg",
      checked: false,
      net: "share-a-sale",
    },
    {
      title: "Tradedoubler",
      image: "https://www.affluent.io/images/platforms/tradedoubler-logo.png",
      checked: false,
      net: "trade-doubler",
    },
    {
      title: "TradeTracker",
      image: "https://www.affluent.io/images/platforms/tradetracker.png",
      checked: false,
      net: "trade-tracker",
    },
    {
      title: "Tune",
      image: "https://www.affluent.io/images/platforms/tune-logo.png",
      checked: false,
      net: "tune",
    },
    {
      title: "Webgains",
      image: "https://www.affluent.io/images/platforms/webgains.png",
      checked: false,
      net: "web-gains",
    },
  ];

  const rename = {
    name: "Name",
    email: "Email",
    pwd: " Password",
    re_pwd: "Comfirm Password",
    org_name: "Organization name",
    position: "Position in Company",
    payment: "",
  };

  const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
  };

  const checkValidation = () => {
    let flag = true;
    if (open === 0) {
      for (let i = 0; i < open1.length; i++) {
        let val = open1[i];

        // if (val === "pwd" || val == "re_pwd" || val == "position") {
        if (invitation[val]?.length === 0) {
          detailsCheck[val] = `${rename[val]} required !`;

          setDetailsCheck({
            ...detailsCheck,
          });

          flag = false;
        } else {
          detailsCheck[val] = ``;
          setDetailsCheck({
            ...detailsCheck,
          });
        }
        // }
      }

      if (invitation?.email?.length !== 0) {
        if (!validator.isEmail(invitation.email)) {
          detailsCheck["email"] = `Invalid email !`;
          setDetailsCheck({
            ...detailsCheck,
          });
          flag = false;
        } else {
          detailsCheck["email"] = ``;
          setDetailsCheck({
            ...detailsCheck,
          });
        }
      }

      if (
        invitation.pwd.length !== 0 &&
        invitation.re_pwd !== 0 &&
        invitation.pwd !== invitation.re_pwd
      ) {
        detailsCheck.re_pwd = `Confirm password does not match with password!`;
        setDetailsCheck({
          ...detailsCheck,
        });
        flag = false;
      }
    } else if (open === 1) {
      for (let i = 0; i < open2.length; i++) {
        let val = open2[i];

        if (invitation[val].length === 0) {
          detailsCheck[val] = `${rename[val]} required !`;
          setDetailsCheck({
            ...detailsCheck,
          });

          // return false;
          flag = false;
        } else {
          detailsCheck[val] = ``;
          setDetailsCheck({
            ...detailsCheck,
          });
        }
      }
    } else if (open === 2) {
      if (isObjectEmpty(networkCollection)) {
        Toastify("error", "Atleast One network must be added !!");
        flag = false;
      }
    } else if (open === 3) {
      if (invitation.payment.length === 0) {
        Toastify("error", "Please Select your subscription plan!");
        flag = false;
      }
      // for (let i = 0; i < open3.length; i++) {
      //   let val = open3[i];

      //   if (details[val].length === 0) {
      //     detailsCheck[val] = `${rename[val]} required !`;
      //     setDetailsCheck({
      //       ...detailsCheck,
      //     });
      //     console.log(details[val].length, val);
      //     // return false;
      //     flag = false;
      //   } else {
      //     detailsCheck[val] = ``;
      //     setDetailsCheck({
      //       ...detailsCheck,
      //     });
      //   }
      // }
    }
    return flag;
  };

  const checkStage = () => {
    // console.log(canShowDialogLeavingPage, 'canShowDialogLeavingPage')
    for (let i = 0; i < open1.length; i++) {
      let key = open1[i];
      if (invitation[key] === "") {
        localStorage.setItem("stage", 0);
        return;
      }
    }
    if (!checkValidation()) {
      localStorage.setItem("stage", 0);
      return;
    }
    localStorage.setItem("stage", 1);

    for (let i = 0; i < open2.length; i++) {
      let key = open2[i];
      if (invitation[key] === "") {
        localStorage.setItem("stage", 1);
        return;
      }
    }

    if (!checkValidation()) {
      localStorage.setItem("stage", 1);
      return;
    }

    localStorage.setItem("stage", 2);
    if (isObjectEmpty(networkCollection)) {
      localStorage.setItem("stage", 2);
      return;
    }

    if (!checkValidation()) {
      localStorage.setItem("stage", 2);
      return;
    }
    localStorage.setItem("stage", 3);
    // setCanShowDialogLeavingPage(false);
  };

  // const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
  //   React.useState(true);
  // const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
  //   useNavigatingAway(canShowDialogLeavingPage);
  const navigator = useNavigate();

  const [currentNet, setCurrentNet] = useState("");
  const [currentNetImg, setCurrentNetImg] = useState("");

  const getForm = (form, Connect, setInvitation, invitation) => {
    switch (form) {
      case "impact":
        return <Impact currentNetImg={currentNetImg} Connect={Connect} />;

      case "admitad":
        return <Admitad currentNetImg={currentNetImg} Connect={Connect} />;

      case "ad-traction":
        return <AdTraction currentNetImg={currentNetImg} Connect={Connect} />;

      case "affiliate-future":
        return (
          <AffiliateFuture currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "avant-link":
        return <AvantLink currentNetImg={currentNetImg} Connect={Connect} />;
      case "awin":
        return <Awin currentNetImg={currentNetImg} Connect={Connect} />;
      case "commission-factory":
        return (
          <CommisionFactory currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "commission-junction":
        return (
          <CommisionJunction currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "daisy-con":
        return <DaisyCon currentNetImg={currentNetImg} Connect={Connect} />;
      case "ever-flow":
        return <EverFlow currentNetImg={currentNetImg} Connect={Connect} />;
      case "link-connector":
        return (
          <LinkConnector currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "odyssey":
        return <Odyssey currentNetImg={currentNetImg} Connect={Connect} />;
      case "one-network-direct":
        return (
          <OneNetworkDirect currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "paid-on-results":
        return (
          <PaidOnResults currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "partnerize":
        return <Partnerize currentNetImg={currentNetImg} Connect={Connect} />;
      case "partner-stack":
        return <PartnerStack currentNetImg={currentNetImg} Connect={Connect} />;
      case "pepper-jam":
        return <PepperJam currentNetImg={currentNetImg} Connect={Connect} />;
      case "rakuten-linkshare":
        return (
          <RakutenLinkshare currentNetImg={currentNetImg} Connect={Connect} />
        );
      case "refersion":
        return <Refersion currentNetImg={currentNetImg} Connect={Connect} />;
      case "share-a-sale":
        return <ShareASale currentNetImg={currentNetImg} Connect={Connect} />;
      case "trade-tracker":
        return <TradeTracker currentNetImg={currentNetImg} Connect={Connect} />;
      case "trade-doubler":
        return <TradeDoubler currentNetImg={currentNetImg} Connect={Connect} />;
      case "tune":
        return <Tune currentNetImg={currentNetImg} Connect={Connect} />;
      case "web-gains":
        return <WebGains currentNetImg={currentNetImg} Connect={Connect} />;
      default:
        return "affiliate-future";
    }
  };
  const [networkCollection, setNetworkCollection] = useState({});

  const Connect = (obj, net) => {
    let networks_collection = networkCollection;
    networks_collection[net] = obj;
    setNetworkCollection(networks_collection);
    setCurrentNet("");
    localStorage.setItem(
      "networks_collection",
      JSON.stringify(networks_collection)
    );
    // console.log(networks_collection, "networks_collection");
  };

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );
    if (networks_collection !== null && networks_collection !== undefined) {
      setNetworkCollection(networks_collection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [onboarding, setOnboarding] = useState(false);
  const onboard = async () => {
    try {
      setOnboarding(true);

      const respone_1 = await axios.post(
        "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/onboarding",
        {
          token: invite_token,
          org_name: invitation.name,
          org_img:
            invitation.org_img !== "" &&
            invitation.org_img !== null &&
            invitation.org_img !== undefined
              ? 1
              : 0,
          org_image_file: invitation.org_img,
          password: invitation.pwd,
          network: invitation.network,
          position: invitation.position,
          payment_required: 1,
          payment_model:
            paymentDetails.payment_model === "" ||
            paymentDetails.payment_model === null ||
            paymentDetails.payment_model === undefined
              ? null
              : paymentDetails.payment_model,
          subscription_id:
            paymentDetails.subscription_id === "" ||
            paymentDetails.subscription_id === null ||
            paymentDetails.subscription_id === undefined
              ? null
              : paymentDetails.subscription_id,
          customer_id:
            paymentDetails.customer_id === "" ||
            paymentDetails.customer_id === null ||
            paymentDetails.customer_id === undefined
              ? null
              : paymentDetails.customer_id,
          coupon: payment?.coupon?.coupon?.coupon,
        }
      );
      localStorage.clear();

      delete respone_1.data.details.password;
      localStorage.setItem(
        "admin_auth",
        JSON.stringify({
          ...respone_1.data.details,
          user: respone_1.data.user_profile.id,
        })
      );

      delete respone_1.data.user_profile.password;
      localStorage.setItem(
        "user_auth",
        JSON.stringify(respone_1.data.user_profile)
      );
      setOnboarding(false);
      sessionStorage.setItem("register", 1);
      navigator("/home");
      Toastify("Success", "Success! Check your email for the invoice.");
    } catch (error) {
      setOnboarding(false);

      console.log(error, "error.response.data.message");
    }
  };

  const pay_onbarod = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();
    if (open < 3) {
      if (checkValidation()) {
        if (!passwordSchema.validate(invitation.pwd)) {
          detailsCheck.pwd =
            "Password should be of length 6 - 10 and should be containing atleast 1 uppercase, 1 lowercase and 1 digit and should not have any spaces";
          setDetailsCheck({
            ...detailsCheck,
          });
          return;
        }
        setOpen(open + 1);
      }
    } else {
      SetShowPaymentDialog(true);
    }
  };

  const [showCost, setShowCost] = useState(0);

  let org_cost = useRef(
    keys.priceIdMonthly === invitation.payment ? 1000 : 10000
  );

  console.log(keys.priceIdMonthly, invitation.payment, "payment info");
  useEffect(() => {
    if (payment.coupon == null) {
      setShowCost(showCost);
    } else {
      setShowCost(
        showCost - (showCost * payment?.coupon?.coupon?.percent_off) / 100
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment.coupon]);

  useEffect(() => {
    const cost = keys.priceIdMonthly === invitation.payment ? 1000 : 10000;
    org_cost.current = cost;
    setShowCost(cost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation.payment]);

  const dispatch = useDispatch();

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={currentNet.length === 0 ? false : true}
        onOpen={() => {}}
        onClose={() => setCurrentNet("")}
      >
        <div
          className="right-drawer"
          style={{
            width: "700px",
          }}
        >
          <ThemeProvider theme={theme}>
            {getForm(currentNet, Connect)}
          </ThemeProvider>
        </div>
      </SwipeableDrawer>
      <Dialog disableEscapeKeyDown open={showPaymentDialog}>
        <div className="payment-dialog-container">
          <div className="header">
            <img
              alt=""
              src={
                "https://lift.lt.partners/static/media/lift_logo_pink-cloud.7b51fda768fdc959c87a.png"
              }
            />
            <div
              style={{
                fontSize: "1.2rem",
                color: "#b51783",
                margin: "10px 0",
              }}
            >
              You have 7 days trial period
            </div>
            <div className="section">
              <div className="label">
                {invitation.payment === keys.priceIdMonthly
                  ? "Monthly"
                  : "Yearly"}
              </div>
              <div className="value">
                ${invitation.payment === keys.priceIdMonthly ? 1000 : 10000}
              </div>
            </div>

            {payment.coupon !== null && payment.coupon !== undefined ? (
              <div className="section">
                <div className="label">
                  Discount ({payment?.coupon?.coupon?.coupon})
                </div>
                <div className="value">
                  {console.log(
                    showCost,
                    payment?.coupon?.coupon?.percent_off / 100
                  )}
                  - $
                  {(org_cost.current * payment?.coupon?.coupon?.percent_off) /
                    100}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="payment-margin"></div>
            <div className="section net-pay">
              <div className="label">Net Payable</div>
              <div className="value">${showCost}</div>
            </div>
          </div>

          <Payment
            setShowCost={setShowCost}
            org_cost={org_cost.current}
            sub_click={sub_click}
            details={{ ...invitation }}
          />
          <ThemeProvider theme={theme}>
            <div className="btn-container-payment">
              <div
                className={`button-control-close-pay ${
                  payment.payment_process ? "button-control-close-diable" : ""
                }`}
                onClick={() => {
                  if (!payment.payment_process) {
                    dispatch(UnSetCoupon());
                    SetShowPaymentDialog(false);
                  }
                }}
              >
                Close
              </div>
              {!payment.payment_process ? (
                <div
                  className="button-control-pay"
                  onClick={() => {
                    setInvitation({
                      ...invitation,
                      network: networkCollection,
                    });
                    setTimeout(function () {
                      sub_click.current.click();
                    }, 500);
                  }}
                >
                  Pay
                </div>
              ) : (
                <div className="button-control-pay">
                  <CircularProgress
                    style={{
                      color: "#fff",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </div>
              )}
            </div>
          </ThemeProvider>
        </div>
      </Dialog>

      <div className="onboarding-invite">
        {/* <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          setShowDialog={setCanShowDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        /> */}
        <div className="top-bar">
          <div className="logo">
            <img alt="" src={process.env.PUBLIC_URL + "/assets/lift_fav.png"} />
          </div>
        </div>
        <div className={"tab-container"}>
          <ul>
            {data.map((d, i) => {
              return (
                <li
                  key={i}
                  // onClick={() => {
                  //   setOpen(i);
                  // }}
                >
                  <OnBoardTab
                    open={open + 1}
                    tot={data.length}
                    active={i === open}
                    type_content={d.type_content}
                    icon={d.icon}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content-container">
          <h2 className={"header"}>Welcome to LIFT</h2>
          <p className="content">
            Please complete the following steps to continue
          </p>
        </div>
        <div className={"form-container"}>
          <ThemeProvider theme={theme}>
            {open === 0 ? (
              <div className={"form-type form-1"}>
                <TextField
                  required
                  error={detailsCheck.name === "" ? false : true}
                  size={"small"}
                  label="Name"
                  helperText={
                    detailsCheck.name === "" ? "Your Name" : detailsCheck.name
                  }
                  value={invitation.name}
                  name={"name"}
                  style={{
                    width: "100%",
                    marginTop: "25px",
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.email === "" ? false : true}
                  size={"small"}
                  label="Email"
                  helperText={
                    detailsCheck.email === ""
                      ? "Your Email"
                      : detailsCheck.email
                  }
                  value={invitation.email}
                  name={"email"}
                  style={{
                    width: "100%",
                    marginTop: "25px",
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.position === "" ? false : true}
                  size={"small"}
                  label="Position in company"
                  helperText={
                    detailsCheck.position === ""
                      ? "Your Position in company"
                      : detailsCheck.position
                  }
                  value={invitation.position}
                  name={"position"}
                  style={{
                    width: "100%",
                    marginTop: "25px",
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.pwd === "" ? false : true}
                  size={"small"}
                  type={passwordUpdate.current}
                  label="Password"
                  helperText={
                    detailsCheck.pwd === "" ? "Your Password" : detailsCheck.pwd
                  }
                  value={invitation.pwd}
                  name={"pwd"}
                  style={{
                    width: "100%",
                    marginTop: "25px",
                  }}
                  onChange={changeDetails}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            handlePassword("current");
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {passwordUpdate.current === "password" ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  error={detailsCheck.re_pwd === "" ? false : true}
                  size={"small"}
                  type={passwordUpdate.confirm}
                  label="Confirm-Password"
                  helperText={
                    detailsCheck.re_pwd === ""
                      ? "Should be the same as Password"
                      : detailsCheck.re_pwd
                  }
                  value={invitation.re_pwd}
                  name={"re_pwd"}
                  style={{
                    width: "100%",
                    marginTop: "25px",
                  }}
                  onChange={changeDetails}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            handlePassword("confirm");
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {passwordUpdate.confirm === "password" ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            {open === 1 ? (
              <div className={"form-type form-2"}>
                <Upload
                  label="Organization Image"
                  name={"org_img"}
                  setDetails={setInvitation}
                  details={invitation}
                  error={""}
                />
                <TextField
                  required
                  error={detailsCheck.org_name === "" ? false : true}
                  size={"small"}
                  label="Organization Name"
                  helperText={
                    detailsCheck.org_name === ""
                      ? "Your Organization Name"
                      : detailsCheck.org_name
                  }
                  value={invitation.org_name}
                  name={"org_name"}
                  style={{
                    width: "100%",
                    marginTop: "25px",
                  }}
                  onChange={changeDetails}
                />
              </div>
            ) : (
              <></>
            )}

            {open === 2 ? (
              <div className={"form-type form-3"}>
                <div className="net-list">
                  {networks.map((d, i) => {
                    console.log(networkCollection[d?.net], "networks");

                    return (
                      <div
                        key={i}
                        onClick={() => {
                          setCurrentNet(d?.net);
                          setCurrentNetImg(d?.image);
                        }}
                        className="card"
                      >
                        <div className="selection">
                          {networkCollection[d?.net] !== null &&
                          networkCollection[d?.net] !== undefined ? (
                            <CheckCircleRoundedIcon
                              style={{ color: "#b51783" }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="image-holder">
                          <img alt="" src={d.image} />
                        </div>
                        <div className="title">{d.title}</div>
                      </div>
                    );
                  })}
                </div>
                {/* <Upload
                label="Brand Image"
                name={"brand_image"}
                setDetails={setDetails}
                details={details}
                error={""}
              /> */}
                {/* <TextField 
                required
                error={detailsCheck.brand_name === "" ? false : true}
                size={"small"}
                label="Brand Name"
                helperText={
                  detailsCheck.brand_name === ""
                    ? "Your Brand Name"
                    : detailsCheck.brand_name
                }
                name={"brand_name"}
                value={details.brand_name}
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
                onChange={changeDetails}
              />
              <TextField 
                required
                error={detailsCheck.brand_vertical === "" ? false : true}
                size="small"
                label="Brand Vertical"
                helperText={
                  detailsCheck.brand_name === ""
                    ? "Your Brand Vertical"
                    : detailsCheck.brand_name
                }
                name={"brand_vertical"}
                value={details.brand_vertical}
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
                onChange={changeDetails}
              />
              <TextField error=""
                required
                error={detailsCheck.brand_domain === "" ? false : true}
                size="small"
                label="Brand Domain"
                helperText={
                  detailsCheck.brand_name === ""
                    ? "Your Brand Domain"
                    : detailsCheck.brand_name
                }
                name={"brand_domain"}
                value={details.brand_domain}
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
                onChange={changeDetails}
              /> */}
              </div>
            ) : (
              <></>
            )}

            {open === 3 ? (
              <div className={"form-type form-4"}>
                <Pricing changeDetails={changeDetails} details={invitation} />
                {/* <Payment sub_click={sub_click} details={details} /> */}
              </div>
            ) : (
              <></>
            )}
          </ThemeProvider>
        </div>
        <div className="control-section">
          <div className="controls">
            <div
              onClick={() => {
                if (open > 0) {
                  setOpen(open - 1);
                }
              }}
              className="back-control"
            >
              <WestIcon size={30} /> <span>Back</span>
            </div>

            {subscribed || paymentRequired === 0 ? (
              onboarding ? (
                <div className="button-control">
                  <CircularProgress
                    style={{
                      color: "#fff",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    onboard();
                  }}
                  className="button-control"
                >
                  {/* {open == 2 ? "Subscribe" : "Next Step"} */}
                  {"Onboard"}
                </div>
              )
            ) : !payment.payment_process ? (
              <div
                onClick={() => {
                  pay_onbarod();
                }}
                className="button-control"
              >
                {/* {open == 2 ? "Subscribe" : "Next Step"} */}
                {"Next Step"}
              </div>
            ) : (
              <div className="button-control">
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="footer" style={{ paddingTop: "10px" }}>
          <p>All rights reserved | LT Partners</p>
        </div>
      </div>
    </>
  );
};

export default OnBoarding;
