import React from "react";
import "./App.css";
import Landing from "./pages/landing";
import { Route, Routes } from "react-router-dom";
import Auth from "../src/pages/auth/auth";
import Waiting from "../src/pages/waiting/waiting.component";
import { useSelector } from "react-redux";
import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tnc from "./pages/tnc/tnc.component";
import ViewContent from "./pages/blog";
import ViewBlogs from "./pages/view-blogs";
import OnBoarding from "./pages/onboarding";
import "./pages/inviteboarding/inviteonboarding.styles.scss";
import PreLoader from "./components/preloader";
// const Landing = React.lazy(() => import("./pages/landing"));
export const Toastify = (type, msg) => {
  switch (type) {
    case "success":
      toast.success(msg, {
        hideProgressBar: true,
        theme: "colored",
        className: "toast-success-container toast-style-ltp",
      });
      break;
    case "warning":
      toast.warn(msg, {
        hideProgressBar: true,
        theme: "colored",
        className: "toast-wrong-container toast-style-ltp",
      });
      break;
    case "error":
      toast.error(msg, {
        hideProgressBar: true,
        theme: "colored",
        className: "toast-wrong-container toast-style-ltp",
      });
      break;
    case "info":
      toast.info(msg, {
        hideProgressBar: true,
        theme: "colored",
        className: "toast-success-container toast-style-ltp",
      });
      break;
    default:
      toast.info(msg, {
        hideProgressBar: true,
        theme: "colored",
        className: "toast-success-container toast-style-ltp",
      });
      break;
  }
};

function App() {
  const { menu } = useSelector((state) => state.nav_bar);
  React.useEffect(() => {
    if (window.innerWidth <= 1000) {
      if (menu) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    } else {
      document.body.style.overflow = "visible";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  return (
    <>
      <PreLoader />
      <ToastContainer />
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/blogs" element={<ViewBlogs />} />
          <Route path="/content/:page_id" element={<ViewContent />} />
          <Route path="/:subdomain/:endpoint" element={<ViewContent />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/tnc" element={<Tnc />} />
          <Route path="/auth-customer-demo" element={<Auth />} />

          <Route path="/waiting" element={<Waiting />} />
          <Route path="/admin/onboard" element={<OnBoarding />} />

          {/*  */}
          {/* <Route path="/please-wait" element={<PleaseWait />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
