import { Link } from "react-router-dom";
import classes from "./pricing.module.scss";
import { BsCheckLg } from "react-icons/bs";
import { keys } from "../../keys";

const Pricing = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.pricings}>
          <div className={classes.flexbox}>
            <div className={classes.inner_flex1}></div>
            <div className={classes.inner_flex1}>
              <div className={classes.popular}>Most popular</div>
            </div>
          </div>

          <div>
            <div className={classes.flexbox}>
              <div className={`${classes.inner_flex1} `}>
                <h3 className={`${classes.round} ${classes.bg_color1}`}>
                  $1,000
                </h3>
              </div>
              <div className={`${classes.inner_flex1} `}>
                <h3 className={` ${classes.bg_color1}`}>$10,000</h3>
              </div>
            </div>

            {/* <div className={classes.flexbox}>
              <div className={classes.inner_flex1}>
                <div className={`${classes.content_head} ${classes.bg_color1}`}>
                  For teams looking to create connected apps to manage their
                </div>
              </div>
              <div className={classes.inner_flex1}>
                <div className={`${classes.content_head} ${classes.bg_color1}`}>
                  For teams and departments who need to build connected
                </div>
              </div>
            </div> */}

            <div className={classes.flexbox}></div>

            <div className={classes.flexbox}>
              <div className={classes.inner_flex1}>
                <div className={`${classes.price} ${classes.bg_color1}`}>
                  <div className={classes.price_title}>Monthly</div>
                </div>
              </div>
              <div className={classes.inner_flex1}>
                <div className={`${classes.price} ${classes.bg_color1}`}>
                  <div className={classes.price_title}>Yearly</div>
                </div>
              </div>
            </div>

            {/* <div className={classes.flexbox}>
              <div className={classes.inner_flex1}>
                <div className={`${classes.billing} ${classes.bg_color1}`}>
                  <div className={classes.bill}>
                    <span>$1,000$</span> billed monthly
                  </div>
                </div>
              </div>
              <div className={classes.inner_flex1}>
                <div className={`${classes.billing} ${classes.bg_color1}`}>
                  <div className={classes.bill}>
                    <span>$10,000$</span> billed annually
                  </div>
                </div>
              </div>
            </div> */}

            <div className={classes.flexbox}>
              <div className={classes.inner_flex1}>
                <div className={`${classes.button} ${classes.bg_color1}`}>
                  <Link
                    onClick={() => {
                      localStorage.setItem("payment", keys.priceIdMonthly);
                      const e = {
                        target: {
                          name: "payment",
                          value: keys.priceIdMonthly,
                        },
                      };

                      props?.changeDetails(e);
                    }}
                    to={""}
                    className={`${classes.btn_typ1} ${
                      keys.priceIdMonthly === props?.details?.payment
                        ? classes.btn_clicked
                        : ""
                    }`}
                  >
                    Choose Monthly
                  </Link>
                </div>
              </div>
              <div className={classes.inner_flex1}>
                <div className={`${classes.button} ${classes.bg_color1}`}>
                  <Link
                    onClick={() => {
                      localStorage.setItem("payment", keys.priceIdYearly);
                      const e = {
                        target: {
                          name: "payment",
                          value: keys.priceIdYearly,
                        },
                      };
                      props?.changeDetails(e);
                    }}
                    to={""}
                    className={`${classes.btn_typ2} ${
                      keys.priceIdYearly === props?.details?.payment
                        ? classes.btn_clicked
                        : ""
                    }`}
                  >
                    Choose Yearly
                  </Link>
                </div>
              </div>
            </div>

            <div className={classes.flexbox}>
              <div className={classes.inner_flex1}>
                <div
                  className={`${classes.list_container} ${classes.bg_color1}`}
                >
                  {/* <div className={classes.head}>Free includes:</div> */}
                  <div className={classes.list}>
                    {/* {[1, 2].map((d, i) => { */}
                    {/* return ( */}
                    <div className={classes.item}>
                      <div className={classes.icon}>
                        <BsCheckLg />
                      </div>
                      <span>Billed Monthly</span>
                    </div>
                    {/* ); */}
                    {/* })} */}
                  </div>
                </div>
              </div>
              <div className={classes.inner_flex1}>
                <div
                  className={`${classes.list_container} ${classes.bg_color1}`}
                >
                  {/* <div className={classes.head}>Free includes:</div> */}
                  <div className={classes.list}>
                    {/* {[1, 2, 3, 4].map((d, i) => { */}
                    {/* return ( */}
                    <div className={classes.item}>
                      <div className={classes.icon}>
                        <BsCheckLg />
                      </div>
                      <span>Save 20% by paying annually</span>
                    </div>
                    <div className={classes.item}>
                      <div className={classes.icon}>
                        <BsCheckLg />
                      </div>
                      <span>Billed yearly</span>
                    </div>
                    {/* ); */}
                    {/* })} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.mobile_pricing}>
          <div className={`${classes.flexbox} ${classes.flexbox_type2}`}>
            <div className={classes.top_content}>
              <h3>Free</h3>
              <p>
                For individuals or very small teams just getting started with
                Airtable
              </p>
            </div>
            <div className={classes.mid_content}>
              <h2>Free</h2>
              <Link to={"/"}>Choose Free</Link>
            </div>
            <div className={classes.bottom_content}>
              <div className={classes.head}>Free includes:</div>
              <div className={classes.list_container}>
                {[1, 2, 3, 4, 5, 6].map((d, i) => {
                  return (
                    <div key={i} className={classes.item}>
                      Unlimited bases
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={`${classes.flexbox} ${classes.flexbox_type3}`}>
            <div className={classes.top_content}>
              <h3>Free</h3>
              <p>
                For individuals or very small teams just getting started with
                Airtable
              </p>
            </div>
            <div className={classes.mid_content}>
              <h2>Free</h2>
              <Link to={"/"} className={classes.btn_typ2}>
                Choose Free
              </Link>
            </div>
            <div className={classes.bottom_content}>
              <div className={classes.head}>Free includes:</div>
              <div className={classes.list_container}>
                {[1, 2, 3, 4, 5, 6].map((d, i) => {
                  return (
                    <div key={i} className={classes.item}>
                      Unlimited bases
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {props.detailsCheck === '' ? <div style={{ color: "red" }}>
                Choose a plan
            </div> : <></>} */}
    </div>
  );
};

export default Pricing;
