import { Link } from "react-router-dom";
import List from "./List";
import classes from "./menuBar.module.scss";
import { IoClose as Close } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  menu_action,
  navbar_actions,
  navbar_actions1,
} from "../../../../redux/actions";
const MenuBar1 = (props) => {
  const dispatch = useDispatch();

  const { integrationRef, horizontalRef, menu } = useSelector(
    (state) => state.nav_bar
  );

  const handleScroll = () => {
    dispatch(navbar_actions("none"));
    dispatch(navbar_actions1("none"));
    dispatch(menu_action(!menu));

    if (props?.id === "Dashboards") {
      horizontalRef?.current?.scrollIntoView({ behavior: "smooth" });
    } else if (props?.id === "Integrations") {
      integrationRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <nav
      className={`${classes.container} ${
        props.action ? classes.container_action : ""
      }`}
    >
      <ul>
        <li
          className={classes.goback}
          onClick={() => {
            dispatch(navbar_actions("none"));
          }}
        >
          <List action={props.action} sec={0.25} headBack={props.head} />
        </li>
        {props.data.map((info, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                handleScroll();
              }}
            >
              {!info?.data?.length ? (
                <Link to={info.link}>
                  <List
                    nav={info.data}
                    sec={0.25 + 0.2 * index}
                    action={props.action}
                    head={info.head}
                    desc={info.desc}
                  />
                </Link>
              ) : (
                <List
                  nav={info.data}
                  sec={0.25 + 0.2 * index}
                  action={props.action}
                  head={info.head}
                  desc={info.desc}
                />
              )}
            </li>
          );
        })}
        {/* <li
          style={{ transition: `all ${footer_time}s ease` }}
          className={classes.footer}
        >
          
          <div className={classes.mainhead}>{props?.footer_head}</div>
          <div className={classes.desc}>
            <p>{props?.footer_desc}</p>
            <div className={classes.links}>
              <Link>
                <p>Explore Prices</p>
                <Icon />
              </Link>
            </div>
          </div>
          
        </li> */}
      </ul>
      {/* {console.log(props.action,"propasdasds.action")} */}
      <button
        onClick={() => {
          dispatch(navbar_actions("none"));
        }}
        className={classes.close}
      >
        <Close size={25} />
      </button>
    </nav>
  );
};

export default MenuBar1;
