import classes from "./list.module.scss";

const List = (props) => {
  // console.log(props, "props.nav");
  if (props.headBack) {
    return (
      <div
        style={{ transition: `all ${props.sec}s ease` }}
        className={`${classes.container} ${
          props.action ? classes.container_active : ""
        }`}
      >
        <span>{props.goback}</span>
        <div>
          <div className={classes.head}>{props.headBack}</div>
        </div>
      </div>
    );
  }

  if (props) {
    return (
      <div
        style={{ transition: `all ${props.sec}s ease` }}
        className={`${classes.container} ${
          props.action ? classes.container_active : ""
        }`}
      >
        <span>{props.icon}</span>
        <div>
          <div className={classes.head}>{props.head}</div>
          <div className={classes.desc}>{props.desc}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ transition: `all ${props.sec}s ease` }}
      className={`${classes.container} ${
        props.action ? classes.container_active : ""
      }`}
    >
      <div className={classes.head}>{props.head}</div>
      <div className={classes.desc}>{props.desc}</div>
    </div>
  );
};

export default List;
