import img1 from "../../../../assests/compo/BrandScroll/webp/1.0-TPC-Evergreen-GDN-Responsive-Landscape-Logo-Black.webp";
import img2 from "../../../../assests/compo/BrandScroll/webp/1800-flowers-logo.webp";
import img3 from "../../../../assests/compo/BrandScroll/webp/16078-1374391.webp";
import img4 from "../../../../assests/compo/BrandScroll/webp/1614177233151.webp";
// import img5 from "../../../../assests/compo/BrandScroll/webp/AG_Logo_white.webp";
import img6 from "../../../../assests/compo/BrandScroll/webp/Alleyoop_Logo_Red.NoBackground.webp";
// import img7 from "../../../../assests/compo/BrandScroll/webp/ANDIE-A-logo-one-color-rgb-black.webp";
// import img8 from "../../../../assests/compo/BrandScroll/webp/Black.webp";
// import img9 from "../../../../assests/compo/BrandScroll/webp/brandless-logo.webp";
import img10 from "../../../../assests/compo/BrandScroll/webp/caddis_logo_3A_blk-2.webp";
import img11 from "../../../../assests/compo/BrandScroll/webp/carve logo_360x.webp";
import img12 from "../../../../assests/compo/BrandScroll/webp/Cash App Taxes Logo - Green.webp";
import img13 from "../../../../assests/compo/BrandScroll/webp/Cash-App-logo 1.webp";
import img14 from "../../../../assests/compo/BrandScroll/webp/chicos.webp";
// import img15 from "../../../../assests/compo/BrandScroll/webp/church-dwight-logo-tm.webp";
import img16 from "../../../../assests/compo/BrandScroll/webp/chwine logo.webp";
import img17 from "../../../../assests/compo/BrandScroll/webp/ck_logo.webp";
// import img18 from "../../../../assests/compo/BrandScroll/webp/Copy of aera-logo-trim_140x@2x.webp";
import img19 from "../../../../assests/compo/BrandScroll/webp/Copy of prose.webp";
import img20 from "../../../../assests/compo/BrandScroll/webp/Copy of wish_logo_color_rgb (1).webp";
import img21 from "../../../../assests/compo/BrandScroll/webp/cuyana-logo.webp";
import img22 from "../../../../assests/compo/BrandScroll/webp/Dave Logo.webp";
// import img23 from "../../../../assests/compo/BrandScroll/webp/dbg_logo.png";
import img24 from "../../../../assests/compo/BrandScroll/webp/FAS_Logo_INC-Only_22px.webp";
import img25 from "../../../../assests/compo/BrandScroll/webp/GLD Logo copy.png";
import img26 from "../../../../assests/compo/BrandScroll/webp/goldbelly-logo-blue-final_2022.webp";
// import img27 from "../../../../assests/compo/BrandScroll/webp/Group-269.webp";
// import img28 from "../../../../assests/compo/BrandScroll/webp/jamie young_logo.webp";
import img29 from "../../../../assests/compo/BrandScroll/webp/JSDentalLogo.png";
import img30 from "../../../../assests/compo/BrandScroll/webp/LOGO GLASSESUSA-1.webp";
// import img31 from "../../../../assests/compo/BrandScroll/webp/Logo.png";
import img32 from "../../../../assests/compo/BrandScroll/webp/Logo.webp";
// import img33 from "../../../../assests/compo/BrandScroll/webp/logo01.webp";
// import img34 from "../../../../assests/compo/BrandScroll/webp/Logos_HA-stacked-dark.webp";
// import img35 from "../../../../assests/compo/BrandScroll/webp/MH-WordMark_Dark Gold.webp";
// import img36 from "../../../../assests/compo/BrandScroll/webp/MJ Logo Black (2).png";
import img37 from "../../../../assests/compo/BrandScroll/webp/monrow logo.png";
import img38 from "../../../../assests/compo/BrandScroll/webp/MR-Wordmark-Logo-Purple-500X500.webp";
import img39 from "../../../../assests/compo/BrandScroll/webp/natori_logo_1461095269__14320.webp";
import img40 from "../../../../assests/compo/BrandScroll/webp/parachute-logo-1.webp";
import img41 from "../../../../assests/compo/BrandScroll/webp/pistola logo final (1).webp";
import img42 from "../../../../assests/compo/BrandScroll/webp/Red Quicken logo_CMYK_With Shadow.webp";
// import img43 from "../../../../assests/compo/BrandScroll/webp/rvOl-KiS6JJV.webp";
import img44 from "../../../../assests/compo/BrandScroll/webp/Simplifi-by-Quicken-Logo-Color-_1_.webp";
import img45 from "../../../../assests/compo/BrandScroll/webp/snakeriverfarms_logo.webp";
// import img46 from "../../../../assests/compo/BrandScroll/webp/Sunday_Black_Logo_PNG.webp";
import img47 from "../../../../assests/compo/BrandScroll/webp/TheKnot_Invitations_REGULARsize_RGB.webp";
import img48 from "../../../../assests/compo/BrandScroll/webp/TomboyX_Logo_Black_x66_2x.webp";
// import img49 from "../../../../assests/compo/BrandScroll/webp/Wolven-Logo.webp";

export const images = [
  img1,
  img2,
  img3,
  img4,
  // img5,
  img6,
  // img7,
  // img8,
  // img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  // img15,
  img16,
  img17,
  // img18,
  img19,
  img20,
  img21,
  img22,
  // img23,
  img24,
  img25,
  img26,
  // img27,
  // img28,
  img29,
  img30,
  // img31,
  img32,
  // img33,
  // img34,
  // img35,
  // img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  // img43,
  img44,
  img45,
  // img46,
  img47,
  img48,
  // img49,
];
