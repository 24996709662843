import classes from "./apps.module.scss";

const Apps = (props) => {
  return (
    <div className={classes.apps_container}>
      <div className={classes._app_pos}>
        <div className={classes.padding_top}></div>
        <span>
          <img src={props.img} alt="" />
        </span>
        <div className={classes.name}>
          {props.name}
          {/* {console.log(props.name)} */}
        </div>
      </div>
    </div>
  );
};

export default Apps;
