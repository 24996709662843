import classes from "./achivements.module.scss";
import img from "../../../../assests/compo/stars.png";
import AchivementCard from "./card";
import img1 from "../../../../assests/compo/Achievments/agency-preferred-900x230px-300x77.png";
import img2 from "../../../../assests/compo/Achievments/corporate-membership.png";
import img3 from "../../../../assests/compo/Achievments/diamond-impact-logo.png";
import img4 from "../../../../assests/compo/Achievments/Martech-Record_Panalist-Badge-1-150x150.png";

import CardSlider from "../../../Slider/CardSlider";

const data = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  //   {
  //     title: "Market leader across 18 categories",
  //     subtitle: "“Flexible product with near endless possibilities.”",
  //     img: img4,
  //   },
];
const Achivements = () => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.desktop}>
          <div className={classes.desktop_wrapper}>
            <div className={classes.para}>
              {/* <div className={classes.stars}>
                <img src={img} alt="" />
              </div> */}
              <div className={classes.title}>
                <h2>
                  A <strong>proprietary platform</strong> developed by one of
                  the fastest growing woman owned affiliate marketing agency!
                </h2>
                {/* <h2>An <strong>award-winning</strong> platform. <strong>Loved</strong> by customers.</h2> */}
              </div>
              {/* <div className={classes.body}>
                Based on 10,000+ customer reviews.
              </div> */}
            </div>
            <div className={classes.cards}>
              {data.map((d, i) => {
                return (
                  <div key={i}>
                    <AchivementCard
                      key={i}
                      img={d.img}
                      //   title={d.title}
                      //   subtitle={d.subtitle}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.mobile}>
          <div className={classes.wrapper}>
            <div className={classes.mobile_wrapper}>
              <div className={classes.para}>
                <div className={classes.title}>
                  <h2>
                    An <strong>award-winning</strong> platform.{" "}
                    <strong>Loved</strong> by customers.
                  </h2>
                </div>
                {/* <div className={classes.body}>
                  Based on 10,000+ customer reviews.
                </div> */}
                <div className={classes.stars}>
                  <img src={img} alt="" />
                </div>
              </div>
              <div className={classes.cards}>
                <CardSlider data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.shapeContainer}>
        <div className={classes.shape}></div>
      </div>
    </div>
  );
};

export default Achivements;
