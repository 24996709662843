import React from "react";
import classes from "./landing.module.scss";
import img from "../../../../../assests/compo/Landing/Landing Section.webp";

const Landing1 = () => {
  let obj = {};
  let weakMap = new WeakMap();
  obj.weakMap = weakMap;
  return (
    <div className={classes.container}>
      <div className={classes.gapbox}></div>
      <div className={classes.wrapper}>
        <img src={img} alt="" />
      </div>
      <div className={classes.landingContent}>
        <div className={classes.heading}>Partnership Performance Analytics</div>
        <div className={classes.para}>
          Get started with performance marketing dashboards right at your
          fingertips
        </div>
        <a
          rel="noreferrer"
          className={classes.btnWelcome}
          href="https://calendly.com/lt-partners-lacie-steve-john/lift-demo"
          target={"_blank"}
        >
          Get Started
        </a>
      </div>
    </div>
  );
};

export default Landing1;
