import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PasswordValidator from "password-validator";
import validator from "validator";
import Spinner from "react-spinner-material";
import axios from "axios";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import "./register.scss";

import AuthFormInput from "../auth-form-input";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "../modal";
// import { app } from "../../utils/firebase-config";

const Register = ({ changeUserAction }) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleSubmit = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();

    if (name == null || name === "") {
      setNameError("Required!");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("");
      return;
    } else if (email == null || email === "") {
      setNameError("");
      setEmailError("Required!");
      setPasswordError("");
      setConfirmPasswordError("");
      return;
    } else if (password == null || password === "") {
      setNameError("");
      setEmailError("");
      setPasswordError("Required!");
      setConfirmPasswordError("");
      return;
    } else if (confirmPassword == null || confirmPassword === "") {
      setNameError("");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("Required!");
      return;
    } else if (!validator.isEmail(email)) {
      setNameError("");
      setEmailError("Invalid Email!");
      setPasswordError("");
      setConfirmPasswordError("");
      return;
    } else if (!passwordSchema.validate(password)) {
      setNameError("");
      setEmailError("");
      setPasswordError(
        "Password should be of length 6 - 10 and should be containing atleast 1 uppercase, 1 lowercase and 1 digit and should not have any spaces"
      );
      setConfirmPasswordError("");
      return;
    } else if (password !== confirmPassword) {
      setNameError("");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("Doesn't match password");
      return;
    } else {
      setShowModal(true);
    }
  };

  const termsAndConditionsAccepted = () => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setShowModal(false);
    setShowLoading(true);

    // Registering User
    const requestBody = {
      name: name,
      email: email,
      password: password,
    };
    //"http://dev-dot-ltpautomatedpublisherscorecard.uc.r.appspot.com/v2/users/reg",
    axios
      .post(
        "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/register",
        requestBody
      )
      .then((response) => {
        //**       admin_verified email id image name organization token verified
        console.log(response.data, "register");
        navigate("/waiting", {
          state: {
            admin_verified: response.data.admin_verified,
            id: response.data.id,
            image: response.data.image,
            organization: response.data.organization,
            token: response.data.token,
            verified: response.data.verified,
            name: name,
            email: email,
            registration_user: true,
            // team: response.data.team,
          },
        });

        // const authentication = getAuth();

        // createUserWithEmailAndPassword(authentication, email, password).then(
        //   (res) => {
        //     navigate("/waiting", {
        //       state: {
        //         id: response.data.id,
        //         name: name,
        //         email: email,
        //         brand: response.data.brand,
        //         track: response.data.track_usage,
        //         team: response.data.team,
        //       },
        //     });
        //   }
        // );
      })
      .catch((err) => {
        setShowLoading(false);
        setEmailError(err.response.data.message);
      });
  };

  return (
    <div className="reg-component">
      <p className="title-reg">Create Account</p>
      <p className="title-tag">
        Get access to exclusive analytics by LT Partners
      </p>
      <AuthFormInput
        label="Full Name"
        type="text"
        hint="Jane Doe"
        value={name}
        changeFunction={(event) => setName(event.target.value)}
        error={nameError}
      />
      <AuthFormInput
        label="Email"
        type="email"
        hint="jane@lt.partners"
        value={email}
        changeFunction={(event) => setEmail(event.target.value)}
        error={emailError}
      />
      <AuthFormInput
        label="Password"
        type="password"
        hint="paS5w0rD"
        value={password}
        changeFunction={(event) => setPassword(event.target.value)}
        error={passwordError}
      />
      <AuthFormInput
        label="Confirm Password"
        type="password"
        hint="Same as Password"
        value={confirmPassword}
        changeFunction={(event) => setConfirmPassword(event.target.value)}
        error={confirmPasswordError}
      />
      <Button className="register-btn" onClick={handleSubmit}>
        {showLoading ? (
          <div className="loading">
            <Spinner
              className="spin"
              radius={23}
              color={"white"}
              stroke={3}
              visible={true}
            />
            <div className="spacer">Loading</div>
          </div>
        ) : (
          "Create my account"
        )}
      </Button>
      <br />
      <p className="existing">
        Already have an account?{" "}
        <span className="sign-link" onClick={() => changeUserAction("login")}>
          Sign In
        </span>
      </p>

      {/* Terms and Conditions */}
      <Modal show={showModal}>
        <ModalHeader>
          <p>Terms and Conditions</p>
        </ModalHeader>
        <ModalBody>
          <div className="body">
            <ol>
              <li className="main-list">
                <span className="title">Introduction</span>
                <br />
                <br />
                LTP Analytics (owned by LT Partners.) welcomes you to our
                platform (“Service”). Members are able to access our Service via
                our website https://analytics.lt.partners (“Site”) and utilize
                the services of their membership level (each referred to as a
                “Member”). In this Terms of Use Agreement, LT Partners. is
                referred to as “LTP”, “us”, “we” or “our”. By accessing or using
                our Site, you (the “User” or a Member) acknowledge that you have
                read, understand and agree to be bound by this Terms of Use
                Agreement (“Terms of Use” or “Agreement”). We reserve the right,
                at our sole discretion, to change, modify, add, or delete
                portions of these Terms of Use at any time without notice by
                posting the changes to these Terms of Use on this page. The date
                of last revision will be listed at the top of this page. Your
                continued use of the Service or Site after any such changes
                constitutes your acceptance of the new Terms of Use. If you do
                not agree to abide by these or any future Terms of Use, do not
                use or access (or continue to use or access) the Service or
                Site. It is your responsibility to regularly check the Site to
                determine if there have been changes to these Terms of Use and
                to review such changes.
                <br />
                <br />
                PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN
                IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
                OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS,
                AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL
                BE RESOLVED. THIS INTRODUCTION IS FULLY INCORPORATED IN THIS
                AGREEMENT BY THIS REFERENCE.
              </li>
              <li className="main-list">
                <span className="title">Eligibility</span>
                <br />
                <br />
                Membership in the Service is void where prohibited. This Site is
                intended solely for users who are eighteen (18) years of age or
                older. Any registration by, use of or access to the Site by
                anyone under 18 is unauthorized, unlicensed and in violation of
                these Terms of Use. By using the Service or Site, you represent
                and warrant that you are 18 or older, and that you agree to and
                to abide by all of the terms and conditions of this Agreement.
              </li>
              <li className="main-list">
                <span className="title">
                  Registration Data; Account Security
                </span>
                <br />
                <br />
                In consideration of your use of the Site, you agree to:
                <ul>
                  <li>
                    provide accurate, current and complete information about you
                    as may be prompted by any registration forms on the Site
                    (“Registration Data”);
                  </li>
                  <li>
                    maintain the security of your password and identification;
                  </li>
                  <li>
                    maintain and promptly update the Registration Data, and any
                    other information you provide to LTP, to keep it accurate,
                    current and complete;
                  </li>
                  <li>
                    be fully responsible for all use of your account and for any
                    actions that take place using your account.
                  </li>
                </ul>
              </li>
              <li className="main-list">
                <span className="title">
                  Proprietary Rights in Site Content; Limited License
                </span>
                <br />
                <br />
                All content on the Site and available through the Service,
                including designs, text, graphics, pictures, video, information,
                applications, software, music, sound and other files, and their
                selection and arrangement (the “Site Content”), are the
                proprietary property of LTP, its users or its licensors with all
                rights reserved. No Site Content may be modified, copied,
                distributed, framed, reproduced, republished, downloaded,
                displayed, posted, transmitted, or sold in any form or by any
                means, in whole or in part, without LTP’s prior written
                permission, except that the foregoing does not apply to User
                Content (as defined below) that you legally post on the Site or
                that you are authorized to use (only to the extent you are
                authorized to use) by other members. Provided that you are
                eligible for use of the Site, you are granted a limited license
                to access and use the Site and the Site Content and to download
                or print a copy of any portion of the Site Content to which you
                have properly gained access solely for your personal,
                non-commercial use, provided that you keep all copyright or
                other proprietary notices intact. Except for your own User
                Content, you may not upload or republish Site Content on any
                Internet, Intranet or Extranet site or incorporate the
                information in any other database or compilation, and any other
                use of the Site Content is strictly prohibited. Such license is
                subject to these Terms of Use and does not include use of any
                data mining, robots or similar data gathering or extraction
                methods. Any use of the Site or the Site Content other than as
                specifically authorized herein, without the prior written
                permission of LTP, is strictly prohibited and will terminate the
                license granted herein. Such unauthorized use may also violate
                applicable laws including copyright and trademark laws and
                applicable communications regulations and statutes. Unless
                explicitly stated herein, nothing in these Terms of Use shall be
                construed as conferring any license to intellectual property
                rights, whether by estoppel, implication or otherwise. This
                license is revocable at any time without notice and with or
                without cause.
              </li>
              <li className="main-list">
                <span className="title">Trademarks</span>
                <br />
                <br />
                All LTP graphics. logos, designs, trademarks, service marks,
                service names, and trade dress are the sole property of LTP, and
                may be subject to state and/or federal U.S. protection and that
                of other countries. LTP’s trademarks and trade dress may not be
                used, including as part of trademarks and/or as part of domain
                names, in connection with any product or service in any manner
                that is likely to cause confusion and may not be copied,
                imitated, or used, in whole or in part, without the prior
                written permission of the LTP Team.
              </li>
              <li className="main-list">
                <span className="title">User Conduct</span>
                <br />
                <br />
                You understand that except for as expressly provided in your
                service agreement with us, the Service and Site may not be used
                by you for commercial purposes. You represent, warrant and agree
                that no materials of any kind submitted through your account or
                otherwise posted, transmitted, or shared by you on or through
                the Service will violate or infringe upon the rights of any
                third party, including copyright, trademark, privacy, publicity
                or other personal or proprietary rights; or contain libelous,
                defamatory or otherwise unlawful material.
                <br />
                <br />
                In addition, you agree not to use the Service or Site to:
                <br />
                <br />
                harvest or collect email addresses or other contact information
                of other users from the Service or Site by electronic or other
                means for the purposes of sending unsolicited emails or other
                unsolicited communications; use the Service or Site in any
                unlawful manner or in any other manner that could damage,
                disable, overburden or impair the Site; use automated scripts to
                collect information from or otherwise interact with the Service
                or Site; upload, post, transmit, share, store or otherwise make
                available any content that we deem to be harmful, threatening,
                unlawful, defamatory, infringing, abusive, inflammatory,
                harassing, vulgar, obscene, fraudulent, invasive of privacy or
                publicity rights, hateful, or racially, ethnically or otherwise
                objectionable; upload, post, transmit, share, store or otherwise
                make available any videos other than those the are to be used
                expressly pursuant to and compliant with your service agreement
                with us; register for a User account on behalf of an individual
                other than yourself, or register any group or entity unless you
                are expressly authorized to do so; impersonate any person or
                entity, or falsely state or otherwise misrepresent yourself,
                your age or your affiliation with any person or entity; upload,
                post, transmit, share or otherwise make available any
                unsolicited or unauthorized advertising, solicitations,
                promotional materials, “junk mail,” “spam,” “chain letters,”
                “pyramid schemes,” or any other illegal or unethical form of
                solicitation; upload, post, transmit, share, store or otherwise
                make publicly available on the Site any private information of
                any third party, including, addresses, phone numbers, email
                addresses, Social Security numbers and credit card numbers;
                solicit personal information from anyone under 18 or solicit
                passwords or personally identifying information for commercial
                or unlawful purposes; upload, post, transmit, share or otherwise
                make available any material that contains software viruses or
                any other computer code, files or programs designed to
                interrupt, destroy or limit the functionality of any computer
                software or hardware or telecommunications equipment; intimidate
                or harass another; upload, post, transmit, share, store or
                otherwise make available content that would constitute,
                encourage or provide instructions for a criminal offense,
                violate the rights of any party, or that would otherwise create
                liability or violate any local, state, national or international
                law; use or attempt to use another’s account, service or system
                without authorization from LTP, or create a false identity on
                the Service or Site. upload, post, transmit, share, store or
                otherwise make available content that, in the sole judgment of
                LTP, is objectionable or which restricts or inhibits any other
                person from using or enjoying the Site, or which may expose LTP
                or its users to any harm or liability of any type.
              </li>
              <li className="main-list">
                <span className="title">Copyright Complaints</span>
                <br />
                <br />
                We respect the intellectual property rights of others and we
                prohibit users from uploading, posting or otherwise transmitting
                on the Site or service any materials that violate another
                party’s intellectual property rights. When we receive written
                notification of alleged copyright infringement, we promptly
                remove or disable access to the allegedly infringing material in
                accordance with the Digital Millennium Copyright Act. LTP may
                also at its sole discretion limit access to the Site and/or
                terminate the memberships of any users who infringe any
                intellectual property rights of others, whether or not there is
                any repeat infringement. If you believe that any material on the
                Site and/or through the Service infringes upon any copyright
                which you own or control, you may send a written notification of
                such infringement to:
                <br />
                <br />
                <div className="add-name">LT Partners</div>
                <div className="add-add">
                  11700 Mukilteo Speedway
                  <br />
                  Suite 201-1218
                  <br />
                  Mukilteo, WA 98275
                  <br />
                </div>
              </li>
              <li className="main-list">
                <span className="title">Third Party Websites and Content</span>
                <br />
                <br />
                The Service or Site may contain, or you may receive, links to
                other web sites (“Third Party Sites”) as well as information,
                applications, software and other content or items belonging to
                or originating from third parties (the “Third Party
                Applications, Software or Content”). Such Third Party Sites and
                Third Party Applications, Software or Content are not
                investigated, monitored or checked for accuracy,
                appropriateness, or completeness by us, and we are not
                responsible for any Third Party Sites accessed through the Site
                or any Third Party Applications, Software or Content posted on,
                available through or installed from the Site, including the
                content, accuracy, offensiveness, opinions, reliability, privacy
                practices or other policies of or contained in the Third Party
                Sites or the Third Party Applications, Software or Content.
                Inclusion of, linking to or permitting the use or installation
                of any Third Party Site or any Third Party Applications,
                Software or Content does not imply approval or endorsement
                thereof by us. If you decide to leave the Site and access the
                Third Party Sites or to use or install any Third Party
                Applications, Software or Content, you do so at your own risk
                and you should be aware that our terms and policies no longer
                govern. You should review the applicable terms and policies,
                including privacy and data gathering practices, of any site to
                which you navigate from the Site or relating to any applications
                you use or install from the site.
              </li>
              <li className="main-list">
                <span className="title">Terms of Sale</span>
                <br />
                <br />
                Whenever services are available for purchase from LTP, the
                services shall include specific terms, conditions and policies
                applicable to your purchase of services from LTP (“Terms of
                Sale”). By ordering services from LTP through the Site, you
                agree to be bound by and accept the applicable Terms of Sale.
                The Terms of Sale are subject to change without prior notice at
                any time, in LTP’s sole discretion so you should review the
                Terms of Sale each time you make a purchase.
              </li>
              <li className="main-list">
                <span className="title">User Disputes</span>
                <br />
                <br />
                You are solely responsible for your interactions with other
                Members. We reserve the right, but have no obligation, to
                monitor disputes between you and other Members.
              </li>
              <li className="main-list">
                <span className="title">Privacy</span>
                <br />
                <br />
                Please review and print out our Member Privacy Policy. By using
                Site or Service you are consenting to have your personal data
                transferred to and processed in the United States. Our Member
                Privacy Policy is subject to change so you are encouraged to
                review periodically.
              </li>
              <li className="main-list">
                <span className="title">Dislaimers</span>
                <br />
                <br />
                LTP is not responsible or liable in any manner for any Member
                Content or Third Party Applications, Software or Content posted
                on the Site or in connection with the Service, whether posted or
                caused by Members, LTP, by third parties or by any of the
                equipment or programming associated with or utilized in the
                Site, Service. Although we provide rules for Member conduct and
                postings, we do not control and are not responsible for what
                Members post, transmit or share on the Service and are not
                responsible for any offensive, inappropriate, obscene, unlawful
                or otherwise objectionable content you may encounter on the
                Service or in connection with any Member Content or Third Party
                Applications, Software or Content. LTP is not responsible for
                the conduct, whether online or offline, of any Member of the
                Site or Service.
                <br />
                <br />
                The Site, and Service may be temporarily unavailable from time
                to time for maintenance or other reasons. LTP assumes no
                responsibility for any error, omission, interruption, deletion,
                defect, delay in operation or transmission, communications line
                failure, theft or destruction or unauthorized access to, or
                alteration of, Member communications or use of the Service. LTP
                is not responsible for any technical malfunction or other
                problems of any telephone network or the Site, computer systems,
                servers or providers, computer or mobile phone equipment,
                software, failure of email on account of technical problems or
                traffic congestion on the Internet or on the Site or combination
                thereof, including injury or damage to User’s or to any other
                person’s computer, mobile phone, or other hardware or software,
                related to or resulting from using or downloading materials in
                connection with the Web and/or in connection with the Service,
                including any Mobile Client software. Under no circumstances
                will LTP be responsible for any loss or damage, including any
                loss or damage to any Member Content or personal injury or
                death, resulting from anyone’s use of the Site, Service, any
                Member Content or Third Party Applications, Software or Content
                posted on or through the Site, and Service or transmitted to
                Users, or any interactions between users of the Site, whether
                online or offline.
                <br />
                <br />
                THE SITE, THE SERVICE, ANY PLATFORM APPLICATIONS AND THE SITE
                CONTENT ARE PROVIDED “AS-IS” AND LTP DISCLAIMS ANY AND ALL
                REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED,
                INCLUDING IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS
                FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. LTP CANNOT
                GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF
                THE SITE AND/OR THE SERVICE AND/OR ANY PLATFORM APPLICATIONS.
                COMPANY DOES NOT REPRESENT OR WARRANT THAT SOFTWARE, CONTENT OR
                MATERIALS ON THE SITE, THE SERVICE OR ANY PLATFORM APPLICATIONS
                ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT
                THE SITE OR SERVICE ITS SERVERS, OR ANY PLATFORM APPLICATIONS
                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU
                SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY SUCH
                SOFTWARE, CONTENT OR MATERIALS AND USE INDUSTRY-RECOGNIZED
                SOFTWARE TO DETECT AND DISINFECT VIRUSES. WITHOUT LIMITING THE
                FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR
                OTHERWISE OBTAIN CONTENT, MATERIAL, DATA OR SOFTWARE (INCLUDING
                ANY MOBILE CLIENT) FROM OR THROUGH THE SERVICE AND ANY PLATFORM
                APPLICATIONS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL
                BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO
                YOUR MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA OR OTHER
                HARM OF ANY KIND THAT MAY RESULT.
                <br />
                <br />
                LTP reserves the right to change any and all content, software
                and other items used or contained in the Site, Directory and any
                Platform Applications offered by LTP at any time without notice.
              </li>
              <li className="main-list">
                <span className="title"> Limitation on Liability</span>
                <br />
                <br />
                IN NO EVENT WILL LTP OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE
                LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
                DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING
                FROM YOUR USE OF THE SITE OR THE SERVICE, ANY PLATFORM
                APPLICATIONS OR ANY OF THE SITE CONTENT OR OTHER MATERIALS ON,
                ACCESSED THROUGH OR DOWNLOADED FROM THE SITE, EVEN IF LTP IS
                AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, LTP’S
                LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE
                FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
                PAID, IF ANY, BY YOU TO COMPANY FOR THE SERVICE DURING THE TERM
                OF MEMBERSHIP, BUT IN NO CASE WILL LTP’S LIABILITY TO YOU EXCEED
                $1000. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO COMPANY FOR
                THE SERVICE, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY,
                UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO
                DAMAGES OF ANY KIND FROM COMPANY, REGARDLESS OF THE CAUSE OF
                ACTION.
              </li>
              <li className="main-list">
                <span className="title">Termination</span>
                <br />
                <br />
                LTP may terminate your membership, delete your profile and any
                content or information that you have posted on the Site or
                through the Service and/or prohibit you from using or accessing
                the Service or Site (or any portion, aspect or feature of the
                Service or Site) for any reason, or no reason, at any time in
                its sole discretion, with or without notice, including if it
                believes that you are under 18.
              </li>
              <li className="main-list">
                <span className="title">
                  Governing Law; Venue and Jurisdiction
                </span>
                <br />
                <br />
                By visiting or using the Site and/or the Service, you agree that
                the laws of the State of California (USA), except for its
                conflict of law provisions governs your visit, use and this
                Agreement. Each party may apply to the state courts in
                Washington, USA, and, to the extent that federal courts have
                exclusive jurisdiction, in Washington, for injunctive relief
                until such time as an award is rendered or the dispute is
                otherwise resolved, and the Parties agree that those courts and
                JAMS arbitration (in accordance with the “Arbitration” provision
                below) shall be the exclusive for any such actions related to
                this Agreement. Both parties consent to such venue and
                jurisdiction. A party that primarily prevails in an action
                brought under this Agreement is entitled to recover from the
                other party its reasonable attorneys’ fees and costs. This Site
                is controlled and operated from within the United States. LTP
                makes no representation that the Site is appropriate or
                available in locations outside the United States. Those who
                choose to access the Site from other locations do so at their
                own risk and are responsible for compliance with applicable
                laws.
              </li>
              <li className="main-list">
                <span className="title">Arbitration</span>
                <br />
                <br />
                The Parties will attempt in good faith to resolve through
                negotiation any dispute, claim, or controversy arising out of or
                relating to this Agreement or breach, termination, enforcement,
                interpretation or validity thereof, including the determination
                of the scope or applicability of this section (“Dispute”). If
                the Dispute is not resolved through verbal and written
                communications, the matter will be submitted to JAMS, or its
                successor, for arbitration. Any Dispute submitted to arbitration
                shall be determined by arbitration in Los Angeles, California,
                USA before a sole arbitrator, in accordance with the laws of the
                State of California for agreements made in and to be performed
                in that State. The language of the arbitration shall be English.
                The arbitration shall be administered by JAMS pursuant to its
                Comprehensive Arbitration Rules and Procedures. Except with
                respect to a party’s indemnification obligations, the arbitrator
                will have no authority to award punitive damages or any other
                damages not measured by the prevailing party’s actual damages,
                and may not, in any event, make any ruling, finding or award
                that does not conform to the terms and conditions of this
                Agreement. Judgment on the arbitration award (“Award”) may be
                entered in any court having jurisdiction. The arbitrator shall,
                in the Award, allocate all of the costs of the arbitration,
                including the fees of the arbitrator and the reasonable
                attorneys’ fees of the party that primarily prevails, against
                the party that did not primarily prevail.
              </li>
              <li className="main-list">
                <span className="title">Indemnity</span>
                <br />
                <br />
                You agree to indemnify and hold LTP, its subsidiaries and
                affiliates, and each of their directors, officers, agents,
                contractors, partners and employees, harmless from and against
                any loss, liability, claim, demand, damages, costs and expenses,
                including reasonable attorney’s fees, arising out of or in
                connection with any Member Content, any Third Party
                Applications, Software or Content you post or share on or
                through the Site (including through the “Share” features), your
                use of the Service or Site, your conduct in connection with the
                Service or Site or with other users of the Service or Site, or
                any violation of this Agreement or of any law or the rights of
                any third party.
              </li>
              <li className="main-list">
                <span className="title">Submissions</span>
                <br />
                <br />
                You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback or other information about the Site
                or Service (“Submissions”), provided by you to LTP are
                non-confidential and shall become the sole property of LTP. LTP
                shall own exclusive rights, including all intellectual property
                rights, and shall be entitled to the unrestricted use and
                dissemination of these Submissions for any purpose, commercial
                or otherwise, without acknowledgment or compensation to you.
              </li>
              <li className="main-list">
                <span className="title">Definitions and Constructions</span>
                <br />
                <br />
                Unless otherwise specified, the terms “includes”, “including”,
                “e.g.,”, “for example”, and other similar terms are deemed to
                include the term “without limitation” immediately thereafter.
                Terms used in these Terms with the initial letter(s) capitalized
                will have the meaning attributed to them in these Terms.
              </li>
              <li className="main-list">
                <span className="title">General</span>
                <br />
                <br />
                If you are registering on the Site as a business entity, you
                represent that you have the authority to bind the entity to this
                Agreement. LTP may assign any of its rights or obligations under
                the Terms to another party without notice or consent for any
                reason. You and LTP are independent contractors, and no agency,
                partnership, joint venture, employee-employer or
                franchiser-franchisee relationship is intended or created by the
                Terms.
              </li>
              <li className="main-list">
                <span className="title">Other</span>
                <br />
                <br />
                These Member Terms of Use, as amended from time to time,
                constitute the entire agreement between you and LTP regarding
                the use of the Site and/or the Service, superseding any prior
                agreements between you and LTP relating to your use of the Site
                or Service. The failure of LTP to exercise or enforce any right
                or provision of these Terms of Use shall not constitute a waiver
                of such right or provision in that or any other instance. If any
                provision of this Agreement is held invalid, the remainder of
                this Agreement shall continue in full force and effect. If any
                provision of these Terms of Use shall be deemed unlawful, void
                or for any reason unenforceable, then that provision shall be
                deemed severable from these Terms of Use and shall not affect
                the validity and enforceability of any remaining provisions. We
                reserve the right, at its discretion, to modify these Terms at
                any time. Changes to these Terms will be posted on the Website
                or sent to you via email. All changes are effective upon posting
                to the Website. Your continued use of the Website following a
                posting of changes is your agreement to the changes and you are
                bound by the then-current version of these Terms. If any changes
                to these Terms are unacceptable to you, you must discontinue use
                of the Site and Service. LTP reserves the right to suspend or
                deny, in its sole discretion, your access to all or any portion
                of the Site, including for any violation of any provision of
                these Terms. LTP also reserves the right to change the Site
                without notice to you, at any time.
              </li>
            </ol>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-accept" onClick={termsAndConditionsAccepted}>
            ACCEPT
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Register;
