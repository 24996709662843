import classes from "./card.module.scss";

const AchivementCard = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <img alt="" src={props.img} />
      </div>
      {/* <div className={classes.title}>
                {props.title}
            </div>
            <div className={classes.subtitle}>
                {props.substitle}
            </div> */}
    </div>
  );
};

export default AchivementCard;
