import classes from "./navItem.module.scss";
import { HiOutlineChevronRight as Icon } from "react-icons/hi";
import MenuBar1 from "../MenuBar1";
import { useDispatch, useSelector } from "react-redux";
import {
  menu_action,
  navbar_actions,
  navbar_actions1,
} from "../../../../redux/actions";
import { useNavigate } from "react-router-dom";

const NavItem1 = (props) => {
  const dispatch = useDispatch();
  const { priceRef, contentRef, menu } = useSelector((state) => state.nav_bar);
  if (props.id === "products") {
    // console.log(props, "products");
  }
  const commonFun = () => {
    dispatch(navbar_actions("none"));
    dispatch(navbar_actions1("none"));
    dispatch(menu_action(!menu));
  };
  const handleScroll = (ref) => {
    if (typeof ref === "string") {
      commonFun();

      setTimeout(function () {
        if (ref === "prices") {
          priceRef?.current?.scrollIntoView({ behavior: "smooth" });
        } else if (ref === "whatislift") {
          contentRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      {props.type === "more" ? (
        <div
          key={props.id}
          className={`${classes.container} ${
            props.action === props.id ? classes.container_active : ""
          }`}
        >
          <button
            id={props.id}
            onClick={() => {
              dispatch(props.clickAction(`${props.id}`));
              dispatch(navbar_actions1("none"));
            }}
          >
            <span>{props.text}</span>
            <Icon className={classes.icone_nav} />
          </button>
          <MenuBar1
            id={props.id}
            head={props.action}
            action={props.action === props.id}
            clickAction={props.clickAction}
            footer_desc={props.footer_desc}
            footer_head={props.footer_head}
            data={props.data}
          />
        </div>
      ) : (
        <div
          key={props.id}
          onClick={() => {
            if (props.id === "blogs") {
              navigate("/blogs");
              return;
            }
            if (props?.tnc === true) {
              navigate("/", {
                state: {
                  moveto: props.id,
                },
              });
            } else {
              handleScroll(props.id);
            }

            console.log(props.tnc, "tnc");
          }}
          className={`${classes.container} ${
            props.action === props.id ? classes.container_active : ""
          }`}
        >
          <button
            id={props.id}
            onClick={() => {
              dispatch(props.clickAction("none"));
            }}
          >
            <span>{props.text}</span>
          </button>
        </div>
      )}
    </>
  );
};

export default NavItem1;
