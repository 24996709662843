import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./trade-doubler.styles.scss";
import { useState } from "react";
import { money } from "../../const/currency";
import { Toastify } from "../../../../App";
import { useEffect } from "react";
const TradeDoubler = ({ Connect, currentNetImg }) => {
  const currency = money;
  const [details, setDetails] = useState({
    username: "",
    password: "",
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const networks_collection = JSON.parse(
      localStorage.getItem("networks_collection")
    );

    const data = networks_collection?.["trade-doubler"];

    if (data !== undefined && data !== null) {
      console.log(data, "imgpact");
      setDetails({
        ...data,
        network_commision_rate: {
          ...data?.network_commision_rate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPass = () => {
    if (details.username.length === 0 || details.username === "") {
      return "Username required !!";
    } else if (details.password.length === 0 || details.password === "") {
      return "Password required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="trade-doubler-container">
        {" "}
        <TextField
          error={false}
          required
          size="small"
          label="User Name"
          style={{
            margin: "15px 0",
          }}
          name="username"
          value={details.username}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          required
          size="small"
          label="Password"
          type="password"
          style={{
            margin: "15px 0",
          }}
          name="password"
          value={details.password}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          error={false}
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "15px 0",
          }}
          value={details.currency}
        >
          <MenuItem>Please select a timezone..</MenuItem>
          {currency.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: t.option,
                    },
                  });
                }}
                value={t.option}
              >
                {t.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          error={false}
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect(details, "trade-doubler");
        }}
        style={{
          width: "120px",
          margin: "15px 0",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default TradeDoubler;
