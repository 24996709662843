export const timeZone = [
  {
    value: 1,
    time: "(GMT+00:00) GMT",
  },
  {
    value: 2,
    time: "(GMT-11:00) Midway Island, Samoa - Pacific/Midway",
  },
  {
    value: 3,
    time: "(GMT-10:00) Hawaii - Pacific/Honolulu",
  },
  {
    value: 4,
    time: "(GMT-09:00) Alaska - America/Anchorage",
  },
  {
    value: 5,
    time: "(GMT-08:00) Pacific Time (US &amp; Canada) - America/Los_Angeles",
  },
  {
    value: 6,
    time: "(GMT-07:00) Arizona - () - America/Phoenix",
  },
  {
    value: 7,
    time: "(GMT-07:00) Mountain Time (US &; Canada) - America/Denver",
  },
  {
    value: 8,
    time: "(GMT-06:00) Central America - America/Belize",
  },
  {
    value: 9,
    time: "(GMT-06:00) Central Time (US & Canada) - America/Chicago",
  },
  {
    value: 10,
    time: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco - America/Bogota",
  },
  {
    value: 11,
    time: "(GMT-05:00) Eastern Time (US & Canada) - America/New_York",
  },
  {
    value: 12,
    time: "(GMT-04:00) Atlantic Time (Canada) - America/Asuncion",
  },
  {
    value: 13,
    time: "(GMT-04:30) Caracas, La Paz - America/Caracas",
  },
  {
    value: 14,
    time: "(GMT-04:00) Manaus - America/Manaus",
  },
  {
    value: 15,
    time: "(GMT-03:30) Newfoundland - America/St_Johns",
  },
  {
    value: 16,
    time: "(GMT-03:00) Brasilia - America/Sao_Paulo",
  },
  {
    value: 17,
    time: "(GMT-03:00) Buenos Aires, Georgetown - America/Buenos_Aires",
  },
  {
    value: 18,
    time: "(GMT-03:00) Montevideo - America/Montevideo",
  },
  {
    value: 19,
    time: "(GMT-02:00) Mid-Atlantic - America/Noronha",
  },
  {
    value: 20,
    time: "(GMT-01:00) Cape Verde Is. - Atlantic/Cape_Verde",
  },
  {
    value: 21,
    time: "(GMT-01:00) Azores - Atlantic/Azores",
  },
  {
    value: 22,
    time: "(GMT+00:00) Dublin, Edinburgh, Lisbon, London - Europe/London",
  },
  {
    value: 23,
    time: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna - Europe/Berlin",
  },
  {
    value: 24,
    time: "(GMT+02:00) Athens, Bucharest, Istanbul - Europe/Athens",
  },
  {
    value: 25,
    time: "(GMT+02:00) Harare, Pretoria - Europe/Kaliningrad",
  },
  {
    value: 26,
    time: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius - Europe/Helsinki",
  },
  {
    value: 27,
    time: "(GMT+03:00) Moscow, St. Petersburg, Volgograd - Europe/Minsk",
  },
  {
    value: 28,
    time: "(GMT+04:00) Abu Dhabi, Muscat - Asia/Dubai",
  },
  {
    value: 29,
    time: "(GMT+04:00) Baku - Asia/Baku",
  },
  {
    value: 30,
    time: "(GMT+04:30) Kabul - Asia/Kabul",
  },
  {
    value: 31,
    time: "(GMT+05:00) Yekaterinburg - Asia/Tashkent",
  },
  {
    value: 32,
    time: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi - Asia/Calcutta",
  },
  {
    value: 33,
    time: "(GMT+05:45) Katmandu - Asia/Katmandu",
  },
  {
    value: 34,
    time: "(GMT+06:00) Astana, Dhaka - Asia/Dhaka",
  },
  {
    value: 35,
    time: "(GMT+06:30) Yangon (Rangoon) - Asia/Rangoon",
  },
  {
    value: 36,
    time: "(GMT+07:00) Bangkok, Hanoi, Jakarta - Asia/Bangkok",
  },
  {
    value: 37,
    time: "(GMT+07:00) Hovd - Asia/Hovd",
  },
  {
    value: 38,
    time: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi - Asia/Hong_Kong",
  },
  {
    value: 39,
    time: "(GMT+09:00) Osaka, Sapporo, Tokyo - Asia/Tokyo",
  },
  {
    value: 40,
    time: "(GMT+09:30) Darwin - Australia/Darwin",
  },
  {
    value: 41,
    time: "(GMT+10:00) Brisbane - Australia/Brisbane",
  },
  {
    value: 42,
    time: "(GMT+10:00) Canberra, Melbourne, Sydney - Australia/Sydney",
  },
  {
    value: 43,
    time: "(GMT+12:00) Auckland, Wellington - Pacific/Auckland",
  },
  {
    value: 44,
    time: "(GMT+12:00) Fiji, Kamchatka, Marshall Is. - Pacific/Fiji",
  },
];
