import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";

if (process.env.NODE_ENV !== "development") {
  if (window.location.protocol !== "https:") {
    window.location.href =
      "https:" +
      window.location.href.substring(window.location.protocol.length);
  }
  console.log = () => {};
}
console.log("development mode no", process.env.NODE_ENV);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
