import { combineReducers } from "redux";
import navbarReducer from "./nav_bar.reducers";
import paymentReducer from "./payment.reducer";
import reduceReducers from "reduce-reducers";
import userReducer from "./user.reducer";

const combineReducer = combineReducers({
  nav_bar: navbarReducer,
  payment: paymentReducer,
  user: userReducer,
});

const rootReducer = reduceReducers(combineReducer);

export default rootReducer;
