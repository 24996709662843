import classes from "./accordion1.module.scss";
import { AiOutlineRight } from "react-icons/ai";
import img1 from "../../../../assests/compo/Accordian1/1.webp";
import img2 from "../../../../assests/compo/Accordian1/2.webp";
import img3 from "../../../../assests/compo/Accordian1/3.webp";

import { useEffect, useState } from "react";

const Accordian1 = () => {
  const [open, setOpen] = useState(0);
  const [act, setAct] = useState(false);
  const stop = 3;
  useEffect(() => {
    var i = open;
    var interval = setInterval(increment, 10000);

    function increment() {
      i = i + 1;

      if (i === stop) {
        i = 0;
      }

      // console.log(i);
      setOpen(i);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [act]);

  const data = [
    {
      img: img1,
      header: "Discover top metric driving publishers and channels",
      para: "Track top performers based on metric categories such as revenue, traffic, conversions and more.",
    },
    {
      img: img2,
      header: "Keep track of publisher performance",
      para: "Monitor publishers’ growth - revenue, traffic, and ROAS, etc.",
    },
    {
      img: img3,
      header: "Score publishers based on your needs",
      para: "Define what matters the most to you - revenue, traffic, AOV, ROAS, and more - to custom score which publishers are the best.",
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div className={classes.content}>
          <div className={classes.head}>
            <h2>Redefine how you manage partners</h2>
          </div>
          {/* <div className={classes.para}> */}
          <p>
            {/* Connect work at every level and amplify impact when you centralize
              ideas, requests, and plans in Airtable. */}
          </p>
          {/* </div> */}
        </div>
      </div>
      <div className={classes.accordian_main}>
        <div className={classes.wrapper}>
          <div className={classes.lists}>
            {data.map((d, i) => {
              return (
                <div
                  key={i}
                  className={`${classes.accordion}  ${
                    i === open ? classes.accordion_active : ""
                  }`}
                >
                  <div
                    className={`${classes.head_progress} ${
                      i === open ? classes.head_progress_active : ""
                    }`}
                  >
                    <span></span>
                  </div>
                  <button
                    onClick={() => {
                      setOpen(i);
                      setAct(!act);
                    }}
                    className={`${i === open ? classes.button_active : ""}`}
                  >
                    <span>{d.header}</span>
                    <span className={classes.icon_active}>
                      <AiOutlineRight />
                    </span>
                  </button>
                  <div
                    className={`${classes.content} ${
                      i === open ? classes.content_active : ""
                    }`}
                  >
                    <div className={classes.wrapper_content}>
                      <div>
                        <p>{d.para}</p>
                      </div>
                      <figure>
                        <span>
                          <span></span>
                          <img alt="" src={d.img} />
                        </span>
                      </figure>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={classes.image_container}>
            {data.map((d, i) => {
              return (
                <figure
                  key={i}
                  className={`${i === open ? classes.figure_active : ""}`}
                >
                  <span>
                    <span></span>
                    <img alt="" src={d.img} />
                  </span>
                </figure>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordian1;
