import axios from "axios";
import { Toastify } from "../../App";

export const PAYMENT_REQUEST = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "PAYMENT_REQUEST" });
      // let res = await axios().post("/users/login", { ...user });
    } catch (error) {
      // Toastify("error", error.response.data.message);
      dispatch({
        type: "PAYMENT_ERROR",
        payload: { error: error.message },
      });
    }
  };
};

export const PAYMENT_SUCCESS = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "PAYMENT_SUCCESS" });
    } catch (error) {
      dispatch({
        type: "PAYMENT_ERROR",
        payload: { error: error.message },
      });
    }
  };
};

export const CouponSet = (coupon) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_COUPON", payload: { coupon } });
    } catch (error) {
      dispatch({
        type: "UNSET_COUPON",
      });
    }
  };
};

export const UnSetCoupon = () => {
  return async (dispatch) => {
    dispatch({ type: "UNSET_COUPON" });
  };
};

export const PAYMENT_ERROR = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "PAYMENT_ERROR" });
    } catch (error) {
      dispatch({
        type: "PAYMENT_ERROR",
        payload: { error: error.message },
      });
    }
  };
};

export const PaymentModelUpdate = (update, admin, setShowUpdateModel) => {
  // https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/subscription/update

  return async (dispatch) => {
    try {
      dispatch({
        type: "PAYMENT_MODEL_UPDATE_REQUEST",
      });

      await axios.post(
        "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/subscription/update",

        {
          admin_id: update.admin_id,
          price_id: update.price_id,
          model_id: update.model_id,
        },
        {
          headers: {
            Authorization: `JWT ${admin.token}`,
          },
        }
      );

      dispatch({
        type: "GET_ADMIN_PROFILE_EXE",
      });

      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/get/${admin.id}`,
        {
          headers: {
            Authorization: `JWT ${admin.token}`,
          },
        }
      );

      if (res.status == 200) {
        dispatch({
          type: "GET_ADMIN_PROFILE_SUCCESS",
          payload: { admin_profile: res.data },
        });
      } else {
        dispatch({
          type: "GET_ADMIN_PROFILE_ERROR",
        });
      }
      setShowUpdateModel(false);

      Toastify("success", "Updated Payment!");

      dispatch({
        type: "PAYMENT_MODEL_UPDATE_SUCCESS",
      });
      return true;
    } catch (err) {
      console.log(err);
      dispatch({
        type: "PAYMENT_MODEL_UPDATE_FAIL",
      });
    }
  };
};
