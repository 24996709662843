import React from "react";
import { Link } from "react-router-dom";
import classes from "./footer.module.scss";
// import logo from "../../../assests/logo/lift_logo_pink-cloud.png";
import { BsFacebook as Facebook } from "react-icons/bs";
import { AiFillLinkedin as Linkedin } from "react-icons/ai";
import { FaInstagram as Instagram } from "react-icons/fa";
import { FaYoutube as Youtube } from "react-icons/fa";
import { BsTwitter as Twitter } from "react-icons/bs";
import { BsChevronRight as Dir } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.navigations}>
          <nav className={classes.navs}>
            <Link to={""} className={classes.logo}>
              <img
                src={
                  "https://lift-marketing.netlify.app/static/media/lift_logo_pink-cloud.7b51fda768fdc959c87a.png"
                }
              />
            </Link>
            <ul className={classes.nav_options}>
              <li>
                <Link to={""}>What is Lift ?</Link>
              </li>
              <li>
                <Link to={""}>Dashboards</Link>
              </li>
              <li>
                <Link to={""}>Prices</Link>
              </li>
              <li>
                <Link to={""}>Integrations</Link>
              </li>
            </ul>
            <ul className={classes.socials}>
              {/* <li>
                                <Link to={""}>
                                    <Facebook size={18} />
                                </Link>
                            </li> */}
              <li>
                <a
                  href={
                    "https://www.linkedin.com/company/lacie-thompson-marketing"
                  }
                  target={"_blank"}
                >
                  <Linkedin size={20} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/lt.partners/"
                  target={"_blank"}
                >
                  <Instagram size={20} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/LTPartners_/" target={"_blank"}>
                  <Twitter size={20} />
                </a>
              </li>
              {/* <li>
                                <Link to={""}>
                                    <Youtube size={18} />
                                </Link>
                            </li> */}
            </ul>
          </nav>
          <hr />
          <ul className={classes.options}>
            <li>
              <p className={classes.head}>Dashboards</p>
              <ul>
                <li>
                  <Link to={""}>
                    <p>Home</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Top Performers</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Performance Comparison</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Publisher Scorecaed</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Paid Placement</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Network vs. Google Analytics Comp</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Monthly KPIs</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Placements Comparison</p>
                  </Link>
                </li>
              </ul>
              {/* <Link to={""}>
                <p>Network vs. GA Comp</p>
              </Link> */}
            </li>
            <li>
              <p className={classes.head}>Integrations</p>
              <ul>
                {/* <li>
                  <Link to={""}
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    // target={"_blank"}
                  >
                    <p>Affluent</p>
                  </Link>
                </li> */}
                <li>
                  <Link to={""}>
                    <p>AWIN</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Commission Junction</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Google Analytics</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Impact</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Pepperjam</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>Rakuten LinkShare</p>
                  </Link>
                </li>
                <li>
                  <Link to={""}>
                    <p>ShareASale</p>
                  </Link>
                </li>
              </ul>
              {/* <Link to={""}
               onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
              >
                <p>Show more</p>
              </Link> */}
            </li>
            <li>
              <p className={classes.head}>LIFT</p>
              <ul>
                <li>
                  <Link to={""}>
                    <p>What is Lift?</p>
                  </Link>
                </li>
                {/* <li>
                  <Link to={""}>
                    <p>Contact Us</p>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to={""}>
                    <p>Team</p>
                  </Link>
                </li> */}
                <li>
                  <a
                    href="https://calendly.com/lt-partners-lacie-steve-john/lift-demo"
                    target={"_blank"}
                  >
                    <p>Book a Demo</p>
                  </a>
                </li>

                <>
                  {/* <li>
                      <Link to={""} to={"/auth"}>
                        <p>Sign Up</p>
                      </Link>
                    </li> */}
                  <li>
                    <Link to={""}>
                      <p>Login</p>
                    </Link>
                  </li>
                </>
              </ul>
            </li>
          </ul>
          <ul className={`${classes.options_mobile} `}>
            <li className={classes.head_list}>
              <button>
                <p className={classes.head}>Dashboards</p>
                <p className={classes.dir_icon}>
                  <Dir size={17} />
                </p>
              </button>
              <div className={`${classes.options_list}`}>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Home</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Top Performers</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Performance Comparison</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Publisher Scorecaed</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Paid Placement</p>
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link to={""}>
                      <p>Network vs. Google Analytics Comp</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Monthly KPIs</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Placements Comparison</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul className={`${classes.options_mobile}`}>
            <li className={classes.head_list}>
              <button>
                <p className={classes.head}>Integrations</p>
                <p className={classes.dir_icon}>
                  <Dir size={17} />
                </p>
              </button>
              <div className={`${classes.options_list}`}>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>AWIN</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Commission Junction</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Content Calendar</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Google Analytics</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Impact</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Pepperjam</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Rakuten LinkShare</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>ShareASale</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul className={`${classes.options_mobile} `}>
            <li className={classes.head_list}>
              <button>
                <p className={classes.head}>LIFT</p>
                <p className={classes.dir_icon}>
                  <Dir size={17} />
                </p>
              </button>
              <div className={`${classes.options_list}`}>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>What is Lift ?</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      href="https://calendly.com/lt-partners-lacie-steve-john/lift-demo"
                      target={"_blank"}
                    >
                      <p>Book a Demo</p>
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={""}>
                      <p>Login</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul className={classes.navs_options_mobile}>
            <li>
              <Link to={""}>What is Lift ?</Link>
            </li>
            <li>
              <Link to={""}>Dashboards</Link>
            </li>
            <li>
              <Link to={""}>Prices</Link>
            </li>
            <li>
              <Link to={""}>Integration</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.marketing}>
        <div className={classes.wrapper}>
          <div className={classes.features}>
            <ul>
              <li>
                <Link to={""}>Security</Link>
              </li>
              <li>
                <Link to={""}>Privacy</Link>
              </li>

              <li>
                {/* <Link to={""} state={{ action: "login", type: 1 }} to={"/auth"}>
                  Team Login
                </Link> */}
                {/* <a
                  href="https://ltpautomatedpublisherscorecard.uc.r.appspot.com/#/auth"
                  target={"_blank"}
                >
                  Team Login
                </a> */}
              </li>
            </ul>
          </div>
          {/* <div>

                        </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
