import { navbar_constant } from "../const";

const initialState = {
  nav_tab: "none",
  nav_tap_nav: "none",
  menu: false,
  priceRef: null,
  horizontalRef: null,
  integrationRef: null,
  contentRef: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case navbar_constant.NAV_TAB_ACTION:
      state = {
        ...state,
        nav_tab: action.payload.nav_tab,
      };
      break;
    case navbar_constant.NAV_TAB_ACTION_ERROR:
      state = {
        ...state,
        nav_tab: initialState.nav_tab,
      };
      break;
    case navbar_constant.NAV_TAB_ACTION1:
      state = {
        ...state,
        nav_tap_nav: action.payload.nav_tap_nav,
      };
      break;
    case navbar_constant.NAV_TAB_ACTION_ERROR1:
      state = {
        ...state,
        nav_tap_nav: initialState.nav_tap_nav,
      };
      break;

    case navbar_constant.MENU_ACTION:
      state = {
        ...state,
        menu: action.payload.menu,
      };
      break;
    case navbar_constant.MENU_ACTION_ERROR:
      state = {
        ...state,
        menu: initialState.menu,
      };
      break;

    case navbar_constant.PRICE_REF_ACTION:
      state = {
        ...state,
        priceRef: action.payload.priceRef,
      };
      break;
    case navbar_constant.PRICE_REF_ERROR:
      state = {
        ...state,
        priceRef: initialState.priceRef,
      };
      break;

    case navbar_constant.HORIZONTAL_REF_ACTION:
      state = {
        ...state,
        horizontalRef: action.payload.horizontalRef,
      };
      break;
    case navbar_constant.HORIZONTAL_REF_ERROR:
      state = {
        ...state,
        horizontalRef: initialState.horizontalRef,
      };
      break;

    case navbar_constant.INTEGRATION_REF_ACTION:
      state = {
        ...state,
        integrationRef: action.payload.integrationRef,
      };
      break;
    case navbar_constant.INTEGRATION_REF_ERROR:
      state = {
        ...state,
        integrationRef: initialState.integrationRef,
      };
      break;

    case navbar_constant.CONTENT_REF_ACTION:
      state = {
        ...state,
        contentRef: action.payload.contentRef,
      };
      break;
    case navbar_constant.CONTENT_REF_ERROR:
      state = {
        ...state,
        contentRef: initialState.contentRef,
      };
      break;
    default:
      break;
  }

  return state;
};
