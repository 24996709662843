import React from "react";
import "./image-input.scss";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
const Upload = (props) => {
  // const [profile, setProfile] = React.useState(props.details[props.name]);
  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem("invitationInfo"));
    if (data != null && data !== undefined && data[props.name] !== "") {
      //   console.log(data[props.name]);
      setPreview(data[props.name]);
      //   const reader = new FileReader();
      //   reader.onload = () => {
      //     if (reader.readyState == 2) {
      //       setPreview(reader.result);
      //     }
      //   };

      //   reader.readAsDataURL(data[props.name]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [preview, setPreview] = React.useState(null);

  const Update = (img) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreview(reader.result);
        const newdata = {
          ...props.details,
          [props.name]: reader.result,
        };

        // console.log((props.details[props.name].toString()), "update function")
        props.setDetails(newdata);
        localStorage.setItem("invitationInfo", JSON.stringify(newdata));
      }
    };

    reader.readAsDataURL(img);
  };

  return (
    <div className="image-container">
      <input
        onChange={(e) => {
          Update(e.target.files[0]);
        }}
        type="file"
        accept="image/*"
        id="upload_profile"
        hidden
        name={props.name}
      />
      <div className={"img_container"}>
        <label className={"uploadFileBtn"} htmlFor="upload_profile">
          {
            <div className={"upload"}>
              {props.details[props.name] === "" ||
              props.details[props.name].length === 0 ? (
                <div className="preview">
                  <span>
                    <AddPhotoAlternateOutlinedIcon />
                  </span>
                  <span>Upload Image</span>
                </div>
              ) : (
                <img
                  alt=""
                  className={"img"}
                  src={
                    preview === null || preview === undefined
                      ? props.details[props.name]
                      : preview
                  }
                />
              )}
            </div>
          }
        </label>
      </div>
    </div>
  );
};

export default Upload;
