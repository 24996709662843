import classes from "./horizontalscroll.module.scss";
import ScrollCard from "../../../ScrollCard";
import img1 from "../../../../assests/compo/HorizontalScroll/Home.webp";
import img2 from "../../../../assests/compo/HorizontalScroll/Top Performers.webp";
import img3 from "../../../../assests/compo/HorizontalScroll/Performance Comparison.webp";
import img4 from "../../../../assests/compo/HorizontalScroll/Publisher Scorecard.webp";
import img5 from "../../../../assests/compo/HorizontalScroll/Paid Placement.webp";
import img6 from "../../../../assests/compo/HorizontalScroll/GA vs Network.webp";

import img8 from "../../../../assests/compo/HorizontalScroll/Placement Comparison.webp";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { horizontal_ref_action } from "../../../../redux/actions";

const data = [
  {
    img: img1,
    head: "Home",
    content_head: "Home",
    desc: "Get a clear picture of your brand’s affiliate program with monthly vanity metrics and top publisher analytics.",
  },
  {
    img: img2,
    head: "Top Performers",
    content_head: "Top Performers",
    desc: "Look at top publishers and channels based on revenue, traffic, conversions, and more.",
  },
  {
    img: img3,
    head: "Performance Comparison",
    content_head: "Performance Comparison",
    desc: "Understand your brand’s historical performance against customizable periods.",
  },
  {
    img: img4,
    head: "Publisher Scorecard",
    content_head: "Publisher Scorecard",
    desc: "Score publishers in your program based on revenue, AOV, clicks, CR, new users, and more.",
  },
  {
    img: img5,
    head: "Paid Placement",
    content_head: "Paid Placement",
    desc: "Understand your placement’s performance with our state-of-the-art report analysis.",
  },
  {
    img: img6,
    head: "Network vs. Google Analytics Comp",
    content_head: "Network vs. Google Analytics Comp",
    desc: "Figure out the difference between your network and Google Analytics report revenue with a single click.",
  },
  // {
  //   img: img7,
  //   head: "Monthly KPIs",
  //   content_head: "Monthly KPIs",
  //   desc: "Overlook monthly brand performance based on new actions, spend, ROAS, and more.",
  // },
  {
    img: img8,
    head: "Placement Comparison",
    content_head: "Placement Comparison",
    desc: "Find out the best placements to run by comparing their performances at a single place.",
  },
  // repeat
  {
    img: img1,
    head: "Home",
    content_head: "Home",
    desc: "Get a clear picture of your brand’s affiliate program with monthly vanity metrics and top publisher analytics.",
  },
  {
    img: img2,
    head: "Top Performers",
    content_head: "Top Performers",
    desc: "Look at top publishers and channels based on revenue, traffic, conversions, and more.",
  },
  {
    img: img3,
    head: "Performance Comparison",
    content_head: "Performance Comparison",
    desc: "Understand your brand’s historical performance against customizable periods.",
  },
  {
    img: img4,
    head: "Publisher Scorecard",
    content_head: "Publisher Scorecard",
    desc: "Score publishers in your program based on revenue, AOV, clicks, CR, new users, and more.",
  },
  {
    img: img5,
    head: "Paid Placement",
    content_head: "Paid Placement",
    desc: "Understand your placement’s performance with our state-of-the-art report analysis.",
  },
  {
    img: img6,
    head: "Network vs. Google Analytics Comp",
    content_head: "Network vs. Google Analytics Comp",
    desc: "Figure out the difference between your network and Google Analytics report revenue with a single click.",
  },
  // {
  //   img: img7,
  //   head: "Monthly KPIs",
  //   content_head: "Monthly KPIs",
  //   desc: "Overlook monthly brand performance based on new actions, spend, ROAS, and more.",
  // },
  {
    img: img8,
    head: "Placement Comparison",
    content_head: "Placement Comparison",
    desc: "Find out the best placements to run by comparing their performances at a single place.",
  },

  //repeat

  // {
  //   img: img1,
  //   head: "Home",
  //   content_head: "Home",
  //   desc: "Get a clear picture of your brand’s affiliate program with monthly vanity metrics and top publisher analytics.",
  // },
  // {
  //   img: img2,
  //   head: "Top Performers",
  //   content_head: "Top Performers",
  //   desc: "Look at top publishers and channels based on revenue, traffic, conversions, and more.",
  // },
  // {
  //   img: img3,
  //   head: "Performance Comparison",
  //   content_head: "Performance Comparison",
  //   desc: "Understand your brand’s historical performance against customizable periods.",
  // },
  // {
  //   img: img4,
  //   head: "Publisher Scorecard",
  //   content_head: "Publisher Scorecard",
  //   desc: "Score publishers in your program based on revenue, AOV, clicks, CR, new users, and more.",
  // },
  // {
  //   img: img5,
  //   head: "Paid Placement",
  //   content_head: "Paid Placement",
  //   desc: "Understand your placement’s performance with our state-of-the-art report analysis.",
  // },
  // {
  //   img: img6,
  //   head: "Network vs. GA Comp",
  //   content_head: "Network vs. GA Comp",
  //   desc: "Figure out the difference between your network and GA report revenue with a single click.",
  // },
  // {
  //   img: img7,
  //   head: "Monthly KPIs",
  //   content_head: "Monthly KPIs",
  //   desc: "Overlook monthly brand performance based on new actions, spend, ROAS, and more.",
  // },
  // {
  //   img: img8,
  //   head: "Placement Comparison",
  //   content_head: "Placement Comparison",
  //   desc: "Find out the best placements to run by comparing their performances at a single place.",
  // },

  // {
  //     img: img2,
  //     head: "RECRUITING",
  //     content_head: "Simple Application tracking",
  //     desc: "Work with any design process or research technique, whether quantitative or qualitative.",
  // },
  // {
  //     img: img1,
  //     head: "RESEARCH",
  //     content_head: "User Research",
  //     desc: "Work with any design process or research technique, whether quantitative or qualitative.",
  // },
  // {
  //     img: img3,
  //     head: "EVENTS",
  //     content_head: "Virtual Events tracker",
  //     desc: "Work with any design process or research technique, whether quantitative or qualitative.",
  // }
];

const HorizontalScroll = () => {
  const dispatch = useDispatch();
  const horizontal_ref = useRef();
  useEffect(() => {
    dispatch(horizontal_ref_action(horizontal_ref));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horizontal_ref]);
  return (
    <section className={classes.container} ref={horizontal_ref}>
      <div className={classes.heading}>
        <div className={classes.division}>
          <div className={classes.head}>
            <h2>Our Flagship Reports</h2>
            <div className={classes.head_content}>
              <p>
                Fast-track your affiliate marketing analysis with our
                proprietary dashboards.
              </p>
              {/* <button>
                            See template gallery
                        </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.scroll_}>
        <div className={classes.scroll_it}>
          <ul className={classes.scroll_container}>
            {data.map((d, i) => {
              return (
                <li key={i * Math.random(10)}>
                  {/* test */}
                  <ScrollCard
                    idx={i + 1}
                    head={d.head}
                    img={d.img}
                    content_head={d.content_head}
                    desc={d.desc}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HorizontalScroll;
