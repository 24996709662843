import HorizontalScroll from "../../components/PageSections/Landing/HorizontalScroll";
import Integration from "../../components/PageSections/Landing/Integration";
import Pricing from "../../components/PageSections/Landing/Pricing";
import BrandScroll from "../../components/PageSections/Landing/BrandsScroll";
import Achivements from "../../components/PageSections/Landing/Achivements";
import Landing1 from "../../components/PageSections/Landing/Land/landing1";
import ContentSection from "../../components/PageSections/Landing/ContentSection";
import Accordian2 from "../../components/PageSections/Landing/Accordion2";
import Accordian1 from "../../components/PageSections/Landing/Accordion1";
import NavBar from "../../components/partials/NavBarComponents/NavBar";
import ActiveBackground from "../../components/ActiveBackground";
import Footer from "../../components/partials/Footer";
import "./landing.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  menu_action,
  navbar_actions,
  navbar_actions1,
} from "../../redux/actions";
import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Toastify } from "../../App";
import validator from "validator";
import axios from "axios";

const Landing = () => {
  const { state } = useLocation();
  const { priceRef, contentRef, menu } = useSelector((state) => state.nav_bar);
  const dispatch = useDispatch();

  const commonFun = () => {
    dispatch(navbar_actions("none"));
    dispatch(navbar_actions1("none"));
    dispatch(menu_action(!menu));
  };

  const handleScroll = (ref) => {
    if (typeof ref === "string") {
      commonFun();

      setTimeout(function () {
        if (ref === "prices") {
          priceRef?.current?.scrollIntoView({ behavior: "smooth" });
        } else if (ref === "whatislift") {
          contentRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (
      state?.moveto !== null &&
      state?.moveto !== undefined &&
      state?.moveto !== ""
    ) {
      handleScroll(state.moveto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openContactUs, setOpenContactUs] = useState(false);
  const [openContactUsProgress, setOpenContactUsProgress] = useState(false);
  const [message, setmessage] = useState({
    name: "",
    email: "",
    query: "",
  });
  const sendQuery = async () => {
    try {
      if (
        typeof message.name === "undefined" ||
        message.name === null ||
        message.name === ""
      ) {
        Toastify("warning", "Name is required!");
      } else if (
        typeof message.email === "undefined" ||
        message.email === null ||
        message.email === ""
      ) {
        Toastify("warning", "Email is required!");
      } else if (
        typeof message.query === "undefined" ||
        message.query === null ||
        message.query === ""
      ) {
        Toastify("warning", "Query is required!");
      } else if (!validator.isEmail(message.email)) {
        Toastify("warning", "Email is not valid!");
      } else {
        setOpenContactUsProgress(true);
        const res = await axios.post(
          "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/marketing/query",
          {
            ...message,
          }
        );

        if (res.status === 200) {
          setOpenContactUsProgress(false);
          setOpenContactUs(false);
          setmessage({
            name: "",
            email: "",
            query: "",
          });
          Toastify("success", "Message sent !!");
        }
      }
    } catch (error) {
      setOpenContactUs(false);
      setOpenContactUsProgress(false);
      console.log(error, "error");
      Toastify("warning", "Invalid number");
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "rgb(166, 0, 113)",
      },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
    },
  });

  const handleChange = (e) => {
    setmessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Dialog disableEscapeKeyDown open={openContactUs}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Send Query
        </DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            className="query-box"
          >
            <ThemeProvider theme={theme}>
              <div className="top-input">
                <TextField
                  error={false}
                  style={{ width: "100%" }}
                  required
                  size="small"
                  label="Name"
                  name="name"
                  value={message.name}
                  onChange={(event) => handleChange(event)}
                />
                <TextField
                  error={false}
                  style={{ width: "100%" }}
                  required
                  size="small"
                  label="Email"
                  name="email"
                  value={message.email}
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <TextField
                error={false}
                style={{ width: "100%" }}
                required
                size="small"
                label="Query"
                name="query"
                fullWidth
                multiline
                minRows={7}
                value={message.query}
                onChange={(event) => handleChange(event)}
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!openContactUsProgress) {
                setOpenContactUs(!openContactUs);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!openContactUsProgress) {
                sendQuery();
              }
            }}
          >
            <div>
              {!openContactUsProgress ? (
                <p>Yes</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
        </DialogActions>
      </Dialog>
      <header className="main_header">
        <NavBar />
        <ActiveBackground />
      </header>
      <div style={{ paddingTop: "70px" }}>
        <Landing1 />
        <ContentSection />
        <HorizontalScroll />

        <Accordian1 />
        <Integration />
        {/* <ShowCase /> */}
        {/* accordian 2 make css change for responsive */}
        <Accordian2 />
        <BrandScroll />
        <Pricing />
        {/* <Satisfaction /> */}
        {/* make css change response */}
        <Achivements />
      </div>
      <Footer setOpenContactUs={setOpenContactUs} />
    </>
  );
};

export default Landing;
