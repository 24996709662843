import React from "react";
import { Link } from "react-router-dom";
import classes from "./footer.module.scss";
import logo from "../../../assests/logo/lift_logo_pink-cloud.png";
import { AiFillLinkedin as Linkedin } from "react-icons/ai";
import { FaInstagram as Instagram } from "react-icons/fa";
import { BsTwitter as Twitter } from "react-icons/bs";
import { BsChevronRight as Dir } from "react-icons/bs";
import { useState } from "react";
import { useSelector } from "react-redux";

const Footer = ({ setOpenContactUs }) => {
  const [select, setSelect] = useState("");
  const { integrationRef, horizontalRef, contentRef, priceRef } = useSelector(
    (state) => state.nav_bar
  );

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    if (data?.token) {
      setUser(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.navigations}>
          <nav className={classes.navs}>
            <Link className={classes.logo}>
              <img alt="" src={logo} />
            </Link>
            <ul className={classes.nav_options}>
              <li>
                <Link
                  onClick={() => {
                    contentRef?.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  What is LIFT ?
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    horizontalRef?.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  Dashboards
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    priceRef?.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  Prices
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    integrationRef?.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  Integrations
                </Link>
              </li>
            </ul>
            <ul className={classes.socials}>
              {/* <li>
                                <Link>
                                    <Facebook size={18} />
                                </Link>
                            </li> */}
              <li>
                <a
                  rel="noreferrer"
                  href={
                    "https://www.linkedin.com/company/lacie-thompson-marketing"
                  }
                  target={"_blank"}
                >
                  <Linkedin size={20} />
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/lt.partners/"
                  target={"_blank"}
                >
                  <Instagram size={20} />
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://twitter.com/LTPartners_/"
                  target={"_blank"}
                >
                  <Twitter size={20} />
                </a>
              </li>
              {/* <li>
                                <Link>
                                    <Youtube size={18} />
                                </Link>
                            </li> */}
            </ul>
          </nav>
          <hr />
          <ul className={classes.options}>
            <li>
              <p className={classes.head}>Features</p>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Home</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Top Performers</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Performance Comparison</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Publisher Scorecard</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Paid Placement</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Network vs. Google Analytics Comp</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Monthly KPIs</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      horizontalRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Placements Comparison</p>
                  </Link>
                </li>
              </ul>
              {/* <Link>
                <p>Network vs. GA Comp</p>
              </Link> */}
            </li>
            <li>
              <p className={classes.head}>Integrations</p>
              <ul>
                {/* <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    // target={"_blank"}
                  >
                    <p>Affluent</p>
                  </Link>
                </li> */}
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>AWIN</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Commission Junction</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Google Analytics</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Impact</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Pepperjam</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>Rakuten LinkShare</p>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>ShareASale</p>
                  </Link>
                </li>
              </ul>
              {/* <Link
               onClick={() => {
                      integrationRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
              >
                <p>Show more</p>
              </Link> */}
            </li>
            <li>
              <p className={classes.head}>LIFT</p>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      contentRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    <p>What is LIFT?</p>
                  </Link>
                </li>
                {/* <li>
                  <Link>
                    <p>Contact Us</p>
                  </Link>
                </li> */}
                {/* <li>
                  <Link>
                    <p>Team</p>
                  </Link>
                </li> */}
                <li>
                  <a
                    rel="noreferrer"
                    href="https://calendly.com/lt-partners-lacie-steve-john/lift-demo"
                    target={"_blank"}
                  >
                    <p>Book a Demo</p>
                  </a>
                </li>

                <li
                  onClick={() => {
                    setOpenContactUs(true);
                  }}
                >
                  <button
                    rel="noreferrer"
                    className="contact-us"
                    style={{ cursor: "pointer" }}
                  >
                    <p>Contact us</p>
                  </button>
                </li>
                {user ? (
                  <li>
                    <a
                      rel="noreferrer"
                      href={
                        user?.team === 1
                          ? `https://app.lift.lt.partners/testload?_id=${user.id}&token=${user.token}`
                          : `https://app.lift.lt.partners/testload?_id=${user.id}&token=${user.token}&onboard=1`
                      }
                    >
                      Go to LIFT
                    </a>
                  </li>
                ) : (
                  <>
                    {/* <li>
                      <Link to={"/auth"}>
                        <p>Sign Up</p>
                      </Link>
                    </li> */}
                    <li>
                      <Link to={"/auth"}>
                        <p>Login</p>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>
          </ul>
          <ul
            className={`${classes.options_mobile} ${
              select === "Dashboards" ? classes.options_list_active : ""
            }`}
          >
            <li className={classes.head_list}>
              <button
                onClick={() => {
                  if (select === "Dashboards") {
                    setSelect("");
                  } else {
                    setSelect("Dashboards");
                  }
                }}
              >
                <p className={classes.head}>Features</p>
                <p className={classes.dir_icon}>
                  <Dir size={17} />
                </p>
              </button>
              <div className={`${classes.options_list}`}>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Home</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Top Performers</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Performance Comparison</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Publisher Scorecard</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Paid Placement</p>
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Network vs. Google Analytics Comp</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Monthly KPIs</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        horizontalRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Placements Comparison</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul
            className={`${classes.options_mobile} ${
              select === "Integrations" ? classes.options_list_active : ""
            }`}
          >
            <li className={classes.head_list}>
              <button
                onClick={() => {
                  if (select === "Integrations") {
                    setSelect("");
                  } else {
                    setSelect("Integrations");
                  }
                }}
              >
                <p className={classes.head}>Integrations</p>
                <p className={classes.dir_icon}>
                  <Dir size={17} />
                </p>
              </button>
              <div className={`${classes.options_list}`}>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>AWIN</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Commission Junction</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link>
                      <p>Content Calendar</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Google Analytics</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Impact</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Pepperjam</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>Rakuten LinkShare</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        integrationRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>ShareASale</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul
            className={`${classes.options_mobile} ${
              select === "LIFT" ? classes.options_list_active : ""
            }`}
          >
            <li className={classes.head_list}>
              <button
                onClick={() => {
                  if (select === "LIFT") {
                    setSelect("");
                  } else {
                    setSelect("LIFT");
                  }
                }}
              >
                <p className={classes.head}>LIFT</p>
                <p className={classes.dir_icon}>
                  <Dir size={17} />
                </p>
              </button>
              <div className={`${classes.options_list}`}>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        contentRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <p>What is LIFT ?</p>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      rel="noreferrer"
                      href="https://calendly.com/lt-partners-lacie-steve-john/lift-demo"
                      target={"_blank"}
                    >
                      <p>Book a Demo</p>
                    </a>
                  </li>
                </ul>
                <ul>
                  <li
                    onClick={() => {
                      setOpenContactUs(true);
                    }}
                  >
                    <button>
                      <p>Contact us</p>
                    </button>
                  </li>
                </ul>
                <ul>
                  {user ? (
                    <li>
                      <a
                        href={
                          user?.team === 1
                            ? `https://app.lift.lt.partners/testload?_id=${user.id}&token=${user.token}`
                            : `https://app.lift.lt.partners/testload?_id=${user.id}&token=${user.token}&onboard=1`
                        }
                      >
                        <p>Go to LIFT</p>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <Link to={"/auth"}>
                        <p>Login</p>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          </ul>
          <ul className={classes.navs_options_mobile}>
            <li>
              <Link
                onClick={() => {
                  contentRef?.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                What is LIFT ?
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  horizontalRef?.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Dashboards
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  priceRef?.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Prices
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  integrationRef?.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Integration
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.marketing}>
        <div className={classes.wrapper}>
          <div className={classes.features}>
            <ul>
              <li>
                <Link to={"/tnc"} target="_blank">
                  Terms of Use
                </Link>
              </li>
              {/* <li>
                <Link>Privacy</Link>
              </li> */}

              {/* <li> */}
              {/* <Link state={{ action: "login", type: 1 }} to={"/auth"}>
                  Team Login
                </Link> */}
              {/* <a
                  href="https://ltpautomatedpublisherscorecard.uc.r.appspot.com/#/auth"
                  target={"_blank"}
                >
                  Team Login
                </a> */}
              {/* </li> */}
            </ul>
            <ul>
              <li>
                <Link>© LIFT 2023</Link>
              </li>
            </ul>
          </div>
          {/* <div>

                        </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
