import classes from "./list.module.scss";
import { HiOutlineChevronRight as Icon } from "react-icons/hi";
import { BsArrowLeft } from "react-icons/bs";
import MenuBar2 from "../../MenuBar2";
import { useSelector, useDispatch } from "react-redux";
import { navbar_actions1 } from "../../../../../redux/actions";

const List = (props) => {
  const { nav_tap_nav } = useSelector((state) => state.nav_bar);
  const dispatch = useDispatch();

  if (props.headBack) {
    return (
      <div className={classes.root_container_back}>
        <div
          className={classes.more_options}
          onClick={() => {
            dispatch(navbar_actions1(props.head));
          }}
        >
          <div className={classes.icon_Container_back}>
            <BsArrowLeft size={30} />
          </div>
          <div
            style={{
              transition: `left ${props.sec}s ease, opacity ${props.sec}s ease`,
            }}
            className={`${classes.container_back} ${
              props.action ? classes.container_active : ""
            }`}
          >
            <div className={classes.head}>{props.headBack}</div>
          </div>
        </div>
      </div>
    );
  }

  if (props?.nav) {
    // console.log(props.nav, "props.nav.nav");
    return (
      <div className={classes.root_container}>
        <div
          className={classes.more_options}
          onClick={() => {
            dispatch(navbar_actions1(props.head));
          }}
        >
          <div
            style={{
              transition: `left ${props.sec}s ease, opacity ${props.sec}s ease`,
            }}
            className={`${classes.container} ${
              props.action ? classes.container_active : ""
            }`}
          >
            <div className={classes.head}>{props.head}</div>
            <div className={classes.desc}>{props.desc}</div>
          </div>
          <div className={classes.icon_Container}>
            <Icon />
          </div>
        </div>
        {/* <div className={`${classes.menu} ${props.action?classes.menu_active:""}`}> */}
        <MenuBar2
          head={props.head}
          classMenu={`${classes.menu} ${
            props.action ? classes.menu_active : ""
          }`}
          clickAction={navbar_actions1}
          action={props.head === nav_tap_nav}
          data={props?.nav}
        />
        {/* </div> */}
      </div>
    );
  }

  return (
    <div
      style={{
        transition: `left ${props.sec}s ease, opacity ${props.sec}s ease`,
      }}
      className={`${classes.container} ${
        props.action ? classes.container_active : ""
      }`}
    >
      <div className={classes.head}>{props.head}</div>
      <div className={classes.desc}>{props.desc}</div>
    </div>
  );
};

export default List;
