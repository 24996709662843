import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./components/partials/footer";
import NavBar from "./components/partials/NavBarComponents/NavBar";
import "./blogs.style.scss";

const ViewContent = () => {
  const [previewContent, setPreviewContent] = useState(null);
  const navigate = useNavigate();
  const { endpoint, subdomain } = useParams();

  const getContents = async () => {
    try {
      const page_id = `${endpoint}@&${subdomain}`;
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/pages/${page_id}`
      );

      if (res.data.public === 0) {
        navigate("/404");
        return;
      }

      setPreviewContent(res.data);
      console.log(res, "res data");
    } catch (error) {
      console.log(error, "errror");
    }
  };

  useEffect(() => {
    getContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function readingTime() {
    let text = "";
    for (let i = 0; i < previewContent?.blocks.length; i++) {
      text += previewContent?.blocks[i].text;
    }
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time;
  }

  return (
    <div className="preview-container">
      <NavBar />

      <div className="hero-section">
        <div className="background-image">
          <img alt="" src={previewContent?.hero} />
          <div className="overlay"></div>
          <div className="content">
            <h1>{previewContent?.title}</h1>
            <hr />
            <p className="para">{previewContent?.subtitle}</p>
            <p> Jan/ 1/ 23</p>
            <p>{readingTime()} min read</p>
          </div>
        </div>
      </div>
      <div className="content-section">
        {previewContent?.blocks?.map((block) => {
          console.log(block, "block");
          return (
            <div
              className={`content-block ${
                block.type === 3
                  ? "content-block-direction2"
                  : block.type === 4
                  ? "content-block-direction1"
                  : "content-block-normal"
              }`}
            >
              {block?.image ? (
                <div className="img-container">
                  <img alt="" src={block?.image} />{" "}
                </div>
              ) : (
                <></>
              )}
              {block?.image ? <div style={{ width: "30px" }}></div> : <></>}
              {block?.text ? (
                <div
                  className="block"
                  dangerouslySetInnerHTML={{ __html: block?.text }}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default ViewContent;
