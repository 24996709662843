export const navbar_constant = {
  NAV_TAB_ACTION: "NAV_TAB_ACTION",
  NAV_TAB_ACTION_ERROR: "NAV_TAB_ACTION_ERROR",
  NAV_TAB_ACTION1: "NAV_TAB_ACTION1",
  NAV_TAB_ACTION_ERROR1: "NAV_TAB_ACTION_ERROR1",
  MENU_ACTION: "MENU_ACTION",
  MENU_ACTION_ERROR: "MENU_ACTION_ERROR",
  PRICE_REF_ACTION: "PRICE_REF_ACTION",
  PRICE_REF_ERROR: "PRICE_REF_ERROR",

  HORIZONTAL_REF_ACTION: "HORIZONTAL_REF_ACTION",
  HORIZONTAL_REF_ERROR: "HORIZONTAL_REF_ERROR",

  INTEGRATION_REF_ACTION: "INTEGRATION_REF_ACTION",
  INTEGRATION_REF_ERROR: "INTEGRATION_REF_ERROR",

  CONTENT_REF_ACTION: "CONTENT_REF_ACTION",
  CONTENT_REF_ERROR: "CONTENT_REF_ERROR",
};
