import AchivementCard from "../../PageSections/Landing/Achivements/card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./cardslider.css";

const CardSlider = (props) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    // variableWidth:true
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider_container">
      <Slider {...settings}>
        {props.data.map((d, i) => {
          return (
            <div className="div-center" key={i}>
              <AchivementCard
                img={d.img}
                title={d.title}
                subtitle={d.subtitle}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CardSlider;
